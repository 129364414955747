import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  getMembershipSponsor,
  putUpdateMembershipSponsor,
} from "../../../helpers/api";
import { message, Select } from "antd";
import _ from "lodash";
import { RequiredIndicator } from "../../../components";

const sponsorTemplate = {
  sponsorName: "",
  sponsorType: "",
  contact: "",
  email: "",
  location: "",
  teamSize: 0,
};

export default function EditSponsorForm({
  onSuccess,
  sponsorId,
  isEditable = true,
}) {
  const [state, setState] = useState(sponsorTemplate);
  const { Option } = Select;

  const { isError, isFetching } = useQuery({
    queryFn: () => getMembershipSponsor(sponsorId),
    queryKey: ["membership-sponsor", sponsorId],
    onSuccess: (data) => setState(data),
  });

  const updateData = (fieldName, data) => {
    setState({ ...state, [fieldName]: data });
  };

  const { mutateAsync: updateSponsor, isLoading } = useMutation(
    (data) => putUpdateMembershipSponsor(state),
    {
      onSuccess: (data, variables, context) => {
        if (data.status === 200) {
          message.success(data.data.message);
          onSuccess();
          return;
        }
        throw data;
      },
      onError: (error, variables, context) => {
        const err = error?.response?.data?.message;
        if (_.isArray(err)) {
          err.map((err) => message.error(err.message));
        } else {
          message.error(err || "error processing request!");
        }
      },
    }
  );

  return (
    <div className="row">
      <div className="field col-12">
        <label className="mb-0" htmlFor="sponsorName">
          Sponsor Name
          <RequiredIndicator />
        </label>
        <input
          className="input"
          type="text"
          autoFocus
          disabled={!isEditable}
          value={state?.sponsorName}
          onChange={(e) => updateData("sponsorName", e.target.value)}
          id="sponsorName"
          key="sponsorName"
          placeholder="e.g. John"
        />
      </div>
      <div className="field col-12">
        <label className="mb-0" htmlFor="sponsorType">
          Sponsor Type
          <RequiredIndicator />
        </label>
        <Select
          required
          allowClear
          showSearch
          disabled={!isEditable}
          value={state?.sponsorType}
          onChange={(v) => updateData("sponsorType", v)}
          size="large"
          id="sponsorType"
          key="sponsorType"
          className="d-block"
          placeholder="sponsorType"
        >
          <Option value="organization">Organization</Option>
          <Option value="individual">Individual</Option>
        </Select>
      </div>
      <div className="field col-12">
        <label className="mb-0" htmlFor="contact">
          Contact
          <RequiredIndicator />
        </label>
        <input
          className="input"
          type="tel"
          disabled={!isEditable}
          value={state?.contact}
          onChange={(e) => updateData("contact", e.target.value)}
          id="contact"
          key="contact"
          placeholder="e.g. 0200000000"
        />
      </div>
      <div className="field col-12">
        <label className="mb-0" htmlFor="contact">
          Team Size
          <RequiredIndicator />
        </label>
        <input
          className="input"
          type="number"
          min={1}
          required
          tabIndex={1}
          disabled={!isEditable}
          value={state?.teamSize}
          onChange={(e) => updateData("teamSize", e.target.value)}
          id="teamSize"
          key="teamSize"
          placeholder="4"
        />
      </div>
      <div className="field col-12">
        <label className="mb-0" htmlFor="email">
          Email
          <RequiredIndicator />{" "}
        </label>
        <input
          className="input"
          required
          type="email"
          disabled={!isEditable}
          value={state?.email}
          onChange={(e) => updateData("email", e.target.value)}
          id="email"
          key="email"
          placeholder="e.g. hr@company.com"
        />
      </div>
      <div className="field col-12">
        <label className="mb-0" htmlFor="location">
          Location
        </label>
        <input
          className="input"
          type="location"
          disabled={!isEditable}
          value={state?.location}
          onChange={(e) => updateData("location", e.target.value)}
          id="location"
          key="location"
          placeholder="e.g. hr@company.com"
        />
      </div>

      <div className="field mt-3">
        {isEditable && (
          <button
            onClick={updateSponsor}
            type="submit"
            id="submit"
            className={`button app-btn btn-prim h6 ${
              isLoading && " is-loading "
            }`}
          >
            <span className="bi bi-check-all me-2"></span>
            Update Record
          </button>
        )}
      </div>
    </div>
  );
}
