import { useState } from "react";
import { useQuery } from "react-query";
import { getAllMembershipSponsors } from "../../../helpers/api";
import { Loader, Menu, Modal } from "@mantine/core";
import { Divider, Table } from "antd";
import { IconMenu } from "@tabler/icons";
import { capitalize } from "lodash";
import SponsoredMembers from "./sponsored-members";
import EditSponsorForm from "../../member/internal/edit-sponsor";
import { hasPermission } from "../../../helpers/utilities";
import { action, resouces } from "../../../helpers/auth";
import { NewMembershipSponsor } from "../../member/internal/new-sponsor";

const MembershipSponsors = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [modal, setModal] = useState({
    title: "",
    open: false,
    content: "",
    size: 500,
    zIndex: 55,
  });

  // queries
  const {
    data: sponsors,
    isFetching,
    refetch,
  } = useQuery({
    queryFn: () => getAllMembershipSponsors(),
    queryKey: ["sponsors"],
    onSuccess: (data) => setFilteredData(data),
  });

  // sponsors table
  const columns = [
    {
      title: "Name",
      sorter: (a, b) => a?.sponsorName > b?.sponsorName,
      dataIndex: "sponsorName",
      sortDirections: ["descend", "ascend"],
      width: 250,
    },
    {
      title: "Type",
      dataIndex: "sponsorType",
      sorter: (a, b) => a?.sponsorType > b?.sponsorType,
      sortDirections: ["descend", "ascend"],
      render: (text, record, index) => capitalize(record.sponsorType),
    },
    {
      title: "Contact",
      dataIndex: "contact",
      sorter: (a, b) => a?.contact > b?.contact,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a?.email > b?.email,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Dependents",
      sorter: (a, b) => a?.Dependents > b?.Dependents,
      sortDirections: ["descend", "ascend"],
      render: (text, record, index) => record.dependants.length || 0,
    },
    {
      title: "-",
      width: 50,
      render: (text, record, index) => (
        <Menu shadow="md" width={160}>
          <Menu.Target>
            <button className="button app-btn is-ghost">
              {/* <IconDotsCircleHorizontal /> */}
              <IconMenu />
            </button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              color="blue"
              onClick={() => {
                setModal({
                  title: "Editing Sponsor",
                  open: true,
                  content: (
                    <EditSponsorForm
                      sponsorId={record.id}
                      onSuccess={refetch}
                      // isEditable={hasPermission(
                      //   resouces.membership,
                      //   action.canUpdate
                      // )}
                    />
                  ),
                  size: 500,
                });
              }}
            >
              <span className="ms-1">Open</span>
            </Menu.Item>
            <Menu.Item
              color="teal"
              onClick={() => {
                setModal({
                  title: `Sponsored Members of '${record.sponsorName}'`,
                  open: true,
                  content: <SponsoredMembers sponsorId={record.id} />,
                  size: 1200,
                });
              }}
            >
              <span className="ms-1">View Dependants</span>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      ),
    },
  ];

  return (
    <div>
      <Modal
        title={modal.title}
        opened={modal.open}
        size={modal.size}
        onClose={() => setModal({ ...modal, open: false })}
        zIndex={modal.zIndex}
      >
        {modal.content}
      </Modal>
      <div>
        <div className="d-flex justify-content-between">
          <input
            type="text"
            className="input w-50"
            placeholder="search by name, type, contact or email"
            onChange={(e) =>
              setFilteredData(
                sponsors.filter(
                  (sv) =>
                    sv.sponsorName
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()) ||
                    sv.sponsorType
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()) ||
                    sv.contact
                      .toString()
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()) ||
                    sv.email
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                )
              )
            }
          />
          <button
            onClick={() => {
              setModal({
                content: (
                  <NewMembershipSponsor isPublic={false} onSuccess={refetch} />
                ),
                open: true,
                title: "New Sponsor",
                size: 500,
              });
            }}
            className="button app-btn btn-prim h6"
          >
            <span className="bi bi-plus-circle me-2" />
            Add
          </button>
        </div>
      </div>
      <Divider />
      {isFetching && <Loader />}
      <Table
        className="border"
        rowKey={(record) => record.id}
        dataSource={filteredData}
        columns={columns}
        loading={isFetching}
        bordered
      />
    </div>
  );
};

export default MembershipSponsors;
