import { useState } from "react";
import { useQuery } from "react-query";
import {
  getAllMemberBenefits,
  getAllMembershipPlanBenefits,
} from "../../helpers/api";
import { Chip, Loader } from "@mantine/core";
import { Divider, Table } from "antd";

export const MemberBenefits = ({ planId, showHeader = true }) => {
  const [filteredData, setFilteredData] = useState([]);

  // queries
  const { data, isFetching, refetch } = useQuery({
    queryFn: () => getAllMembershipPlanBenefits(planId, "id"),
    queryKey: ["member-benefit"],
    onSuccess: (data) => setFilteredData(data),
  });

  // sponsors table
  const columns = [
    {
      title: "Benefits",
      sorter: (a, b) => a?.benefit?.benefitName > b?.benefit?.benefitName,
      sortDirections: ["descend", "ascend"],
      render: (text, record, index) => record?.benefit.benefitName,
      width: 250,
    },
    // {
    //   title: "Total Points",
    //   dataIndex: ["patient", "gender"],
    //   sorter: (a, b) => a?.patient.gender > b?.patient.gender,
    //   sortDirections: ["descend", "ascend"],
    // },
    // {
    //   title: "Used Points",
    //   dataIndex: ["patient", "gender"],
    //   sorter: (a, b) => a?.patient.gender > b?.patient.gender,
    //   sortDirections: ["descend", "ascend"],
    // },
    // {
    //   title: "Balance",
    //   dataIndex: ["patient", "gender"],
    //   sorter: (a, b) => a?.patient.gender > b?.patient.gender,
    //   sortDirections: ["descend", "ascend"],
    // },
  ];

  return (
    <div>
      {showHeader && (
        <>
          <div className="row">
            <div className="col-md-6 col-12">
              <input
                type="text"
                className="input"
                placeholder="search by benefit name"
                onChange={(e) =>
                  setFilteredData(
                    data.filter((sv) =>
                      sv.benefit.benefitName
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    )
                  )
                }
              />
            </div>
          </div>
          <Divider />
          <Chip className="mb-3" disabled>
            showing <strong>{filteredData?.length}</strong> benefits
          </Chip>
          {isFetching && <Loader />}
        </>
      )}
      <Table
        // className="border"
        rowKey={(record) => record.id}
        dataSource={filteredData}
        columns={columns}
        loading={isFetching}
        footer={false}
        tableLayout="auto"
        pagination={false}
        // bordered
      />
    </div>
  );
};
