import axios from "axios";
import { getToken, getExtToken } from "./auth";
import { appUrl } from "./utilities";
import extSecurity from "./extAuth";

export const fetchQuery = async (
  url = "",
  auth = true,
  isExternal = false,
  config
) => {
  try {
    if (auth && !isExternal)
      axios.defaults.headers.common["token"] = getToken();
    if (auth && isExternal)
      axios.defaults.headers.common["Authorization"] = extSecurity.getToken();

    const res = await axios.get(`${appUrl}/${url}`, config);
    return res;
  } catch (ex) {
    return ex;
  }
};

export const postQuery = async (
  url = "",
  data = {},
  auth = true,
  isExternal = false,
  config
) => {
  try {
    if (auth && !isExternal)
      axios.defaults.headers.common["token"] = getToken();
    if (auth && isExternal)
      axios.defaults.headers.common["Authorization"] = extSecurity.getToken();

    const res = await axios.post(`${appUrl}/${url}`, data, config);
    return res;
  } catch (ex) {
    return ex;
  }
};

export const updateQuery = async (
  url = "",
  data = {},
  auth = true,
  isExternal = false,
  config
) => {
  try {
    if (auth && !isExternal)
      axios.defaults.headers.common["token"] = getToken();
    if (auth && isExternal)
      axios.defaults.headers.common["Authorization"] = extSecurity.getToken();

    const res = await axios.put(`${appUrl}/${url}`, data, config);
    return res;
  } catch (ex) {
    return ex;
  }
};

export const patchQuery = async (
  url = "",
  data = {},
  auth = true,
  isExternal = false,
  config
) => {
  try {
    if (auth && !isExternal)
      axios.defaults.headers.common["token"] = getToken();
    if (auth && isExternal)
      axios.defaults.headers.common["Authorization"] = extSecurity.getToken();

    const res = await axios.patch(`${appUrl}/${url}`, data, config);
    return res;
  } catch (ex) {
    return ex;
  }
};

export const deleteQuery = async (
  url = "",
  config,
  auth = true,
  isExternal = false
) => {
  try {
    if (auth && !isExternal)
      axios.defaults.headers.common["token"] = getToken();
    if (auth && isExternal)
      axios.defaults.headers.common["Authorization"] = extSecurity.getToken();

    const res = await axios.delete(`${appUrl}/${url}`, config);
    return res;
  } catch (ex) {
    return ex;
  }
};

// requests

// GET

// users
export const getAllUsers = async () => {
  const qry = await fetchQuery(`accounts`);
  return qry.data.data;
};

export const getAllLogs = async (facilityId) => {
  const qry = await fetchQuery(`accounts/logs/${facilityId}`);
  return qry.data.data;
};

export const getUser = async (id) => {
  const qry = await fetchQuery(`accounts/${id}`);
  return qry.data.data;
};

export const getUserProfile = async (id) => {
  const qry = await fetchQuery(`accounts/${id}/profile`);
  return qry.data.data;
};

export const getUserBranches = async (id) => {
  const qry = await fetchQuery(`accounts/${id}/branches`);
  return qry.data.data;
};

export const getRefreshToken = async () => {
  const qry = await fetchQuery(`accounts/refresh-token`);
  return qry.data;
};

export const putActivateUser = async (userId) => {
  return await updateQuery(`accounts/${userId}/activate`);
};

export const putSuspendUser = async (userId) => {
  return await updateQuery(`accounts/${userId}/deactivate`);
};

export const putUpdateUser = async (data) => {
  return await updateQuery(`accounts`, data);
};

export const deleteUser = async (userId) => {
  return await deleteQuery(`accounts/${userId}`);
};

// patients
export const getAllPatients = async () => {
  // const qry = await fetchQuery( `patients?entireNetwork=${ true }` );
  const qry = await fetchQuery(`patients`);
  return qry.data.data;
};

export const getAllNetworkPatients = async () => {
  const qry = await fetchQuery(`patients?entireNetwork=${true}`);
  return qry.data.data;
};

export const getPatient = async (id) => {
  const qry = await fetchQuery(`patients/${id}`);
  return qry.data.data;
};

export const getPatientMedicalHistory = async (id) => {
  const qry = await fetchQuery(`patients/${id}/medical-history`);
  return qry.data.data;
};

export const postNewPatient = async (patient) => {
  return await postQuery("patients", patient);
};

export const putUpdatePatient = async (patient) => {
  return await updateQuery(`patients`, patient);
};

export const putUpdatePatientMedicalHistory = async (data) => {
  return await updateQuery(`patients/medical-history`, data);
};

export const deletPatient = async (id) => {
  return await deleteQuery(`patients/${id}`);
};

// staffs
export const getAllStaffGroups = async () => {
  const qry = await fetchQuery(`staffs/groups`);
  return qry.data.data;
};

export const getAllStaffs = async () => {
  const qry = await fetchQuery(`staffs`);
  return qry.data.data;
};

export const getStaff = async (id) => {
  const qry = await fetchQuery(`staffs/${id}`);
  return qry.data.data;
};

// services
export const getAllServiceGroups = async () => {
  return await fetchQuery(`services/groups`);
};

export const getAllServices = async () => {
  const qry = await fetchQuery(`services`);
  return qry.data.data;
};

export const getService = async (id) => {
  const qry = await fetchQuery(`services/${id}`);
  return qry.data.data;
};

export const putFacilityService = async (data) => {
  return await updateQuery(`services`, data);
};

export const postFacilityService = async (data) => {
  return await postQuery(`services`, data);
};

export const deleteFacilityService = async (id) => {
  return await deleteQuery(`services/${id}`);
};

// BILLS
export const getAllBills = async () => {
  const qry = await fetchQuery(`bills`);
  return qry.data.data;
};

export const getBill = async (id) => {
  const qry = await fetchQuery(`bills/${id}`);
  return qry.data.data;
};

// export const getBillByReceiptNumber = async ( number ) => {
//   const qry = await fetchQuery( `bills/receipts/${ number }` );
//   return qry.data.data;
// };

/**
 * Retrieves a bill receipt based on the specified value and query.
 *
 * @param {any} query - The value used to search for the receipt.
 * @param {string} param - The type of query to perform. Default is either "billId" or "receiptNumber".
 * @return {Promise} The data of the retrieved bill receipt.
 */
export const getBillReceipt = async (
  param = "billId" | "receiptNumber",
  query
) => {
  const qry = await fetchQuery(`bills/receipts/${param}/${query}`);
  return qry.data.data;
};

export const putBill = async (data) => {
  return await updateQuery(`bills`, data);
};

export const putLinkBillToConsultation = async (billId, code) => {
  return await updateQuery(`bills/${billId}/link-consultation/${code}`);
};

export const postBill = async (data) => {
  return await postQuery(`bills`, data);
};

export const deleteBill = async (id) => {
  return await deleteQuery(`bills/${id}`);
};

// service providers
export const getAllInsuranceProviders = async () => {
  const qry = await fetchQuery(`insurances`);
  return qry.data.data;
};

export const getInsuranceProvider = async (id) => {
  const qry = await fetchQuery(`insurances/${id}`);
  return qry.data.data;
};

export const getAllInsuranceTypes = async () => {
  const qry = await fetchQuery(`insurances/types`);
  return qry.data.data;
};

export const getAllLaboratories = async () => {
  const qry = await fetchQuery(`laboratories`);
  return qry.data.data;
};

export const getLaboratory = async (id) => {
  const qry = await fetchQuery(`laboratories/${id}`);
  return qry.data.data;
};

export const getAllPharmacies = async () => {
  const qry = await fetchQuery(`pharmacies`);
  return qry.data.data;
};

export const getPharmacy = async (id) => {
  const qry = await fetchQuery(`pharmacies/${id}`);
  return qry.data.data;
};

// appointments
export const getAllAppointments = async () => {
  const qry = await fetchQuery(`appointments`);
  return qry.data.data;
};

export const getAppointmentsById = async (id) => {
  const qry = await fetchQuery(`appointments/${id}`);
  return qry.data.data;
};

export const postNewAppointment = async (appointment) => {
  return await postQuery("appointments", appointment);
};

export const putAppointment = async (data) => {
  return await updateQuery(`appointments`, data);
};

export const patchCompleteAppointment = async (id) => {
  return await patchQuery(`appointments/${id}/complete`);
};

export const patchCancelAppointment = async (id, cancelReason) => {
  return await patchQuery(`appointments/${id}/cancel`, cancelReason);
};

export const deleteAppointment = async (id) => {
  return await deleteQuery(`appointments/${id}`);
};

// auth
export const postSignup = async (user) => {
  return await postQuery("accounts/signup", user);
};

export const postLogin = async (user) => {
  return await postQuery("accounts/login", user, false);
};

export const postChangePassword = async (user) => {
  return await postQuery("accounts/change-password", user);
};

export const postForgotPassword = async (email) => {
  return await postQuery("accounts/forgot-password", email, false);
};

export const postAttendance = async (data) => {
  return await postQuery(`accounts/attendance`, data);
};

export const putAttendance = async (data) => {
  return await updateQuery(`accounts/attendance`, data);
};

export const putLogOut = async () => {
  return await updateQuery(`accounts/logout`);
};

export const putSwitchBranch = async (id) => {
  return await updateQuery(`accounts/switch-branch/${id}`);
};

// staff
export const postNewStaff = async (staff) => {
  return await postQuery("staffs", staff);
};

export const putUpdateStaff = async (staff) => {
  return await updateQuery("staffs", staff);
};

export const postNewStaffGroup = async (group) => {
  return await postQuery("staffs/groups", group);
};

export const deleteStaff = async (id) => {
  return await deleteQuery(`staffs/${id}`);
};

// services
export const postNewServiceGroup = async (group) => {
  return await postQuery("services/groups", group);
};

export const postNewService = async (service) => {
  return await postQuery("services", service);
};

// service providers
export const postNewInsurance = async (insurance) => {
  // return await axios.post(appUrl + "/insurances", insurance);
  return await postQuery("insurances", insurance);
};

export const putUpdateInsurance = async (insur) => {
  return await updateQuery("insurances", insur);
};

export const deleteInsurance = async (id) => {
  return await deleteQuery(`insurances/${id}`);
};

// insurance type
export const postNewInsuranceType = async (type) => {
  return await postQuery("insurances/types", type);
};

export const putUpdateInsuranceType = async (insurType) => {
  return await updateQuery("insurances/types", insurType);
};

// laboratories
export const postNewLaboratory = async (lab) => {
  return await postQuery("laboratories", lab);
};

export const putUpdateLaboratory = async (lab) => {
  return await updateQuery("laboratories", lab);
};

export const deleteLaboratory = async (id) => {
  return await deleteQuery(`laboratories/${id}`);
};

// pharmacies
export const postNewPharmacy = async (pharma) => {
  return await postQuery("pharmacies", pharma);
};

export const putUpdatePharmacy = async (pharma) => {
  return await updateQuery("pharmacies", pharma);
};

export const deletePharmacy = async (id) => {
  return await deleteQuery(`pharmacies/${id}`);
};

// consultation
export const postNewConsultation = async (consultation) => {
  return await postQuery("consultations", consultation);
};

export const putUpdateConsultationStatus = async (id) => {
  return await updateQuery(`consultations/${id}/toggle-status`);
};

export const deleteConsultation = async (id) => {
  return await deleteQuery(`consultations/${id}`);
};

export const getConsultationByCode = async (code) => {
  return await fetchQuery(`consultations/${code}/code`);
};

export const getConsultationById = async (id) => {
  // return await fetchQuery( `consultations/${ id }` );
  const qry = await fetchQuery(`consultations/${id}`);
  return qry.data.data;
};

export const getConsultationFeedback = async (id) => {
  // return await fetchQuery( `consultations/${ id }` );
  const qry = await fetchQuery(`consultations/${id}/feedback`);
  return qry.data.data;
};

export const getConsultationsByPatient = async (patientId) => {
  const qry = await fetchQuery(`consultations/${patientId}/patient`);
  return qry.data.data;
};

export const getAllConsultations = async () => {
  const qry = await fetchQuery(`consultations`);
  return qry.data.data;
};

export const getSendReminder = async (id) => {
  return await fetchQuery(`consultations/${id}/reminder`);
};

// subsets
export const getConsultationOnly = async (id) => {
  const qry = await fetchQuery(`consultations/${id}/light`);
  return qry.data.data;
};

export const getCareplans = async (id) => {
  const qry = await fetchQuery(`consultations/${id}/careplans`);
  return qry.data.data;
};

export const getCareplanById = async (id) => {
  const qry = await fetchQuery(`consultations/careplans/${id}`);
  return qry.data.data;
};

export const getVitals = async (id) => {
  const qry = await fetchQuery(`consultations/${id}/vitals`);
  return qry.data.data;
};

export const getDiagnosis = async (id) => {
  const qry = await fetchQuery(`consultations/${id}/diagnosis`);
  return qry.data.data;
};

export const getReviews = async (id) => {
  const qry = await fetchQuery(`consultations/${id}/reviews`);
  return qry.data.data;
};

export const getPrescriptions = async (id) => {
  const qry = await fetchQuery(`consultations/${id}/prescriptions`);
  return qry.data.data;
};

export const getPharmacies = async (id) => {
  const qry = await fetchQuery(`consultations/${id}/pharmacies`);
  return qry.data.data;
};

export const getLaboratories = async (id) => {
  const qry = await fetchQuery(`consultations/${id}/laboratories`);
  return qry.data.data;
};

export const getExcuseDuties = async (code) => {
  const qry = await fetchQuery(`consultations/${code}/excuse-duties`);
  return qry.data.data;
};

export const getExcuseDuty = async (code, id) => {
  const qry = await fetchQuery(`consultations/${code}/excuse-duties/${id}`);
  return qry.data.data;
};

export const postExcuseDuty = async (data) => {
  return await postQuery(`consultations/excuse-duties`, data);
};

export const postCareplan = async (data) => {
  return await postQuery(`consultations/careplans`, data);
};

// update subsets
export const putCarePlan = async (data) => {
  return await updateQuery(`consultations/careplans`, data);
};

export const putConsultationOnly = async (data) => {
  return await updateQuery(`consultations/`, data);
};

export const putVitals = async (id, data) => {
  return await updateQuery(`consultations/${id}/vitals`, data);
};

export const putDiagnosis = async (id, data) => {
  return await updateQuery(`consultations/${id}/diagnosis`, data);
};

export const putReviews = async (id, data) => {
  return await updateQuery(`consultations/${id}/reviews`, data);
};

export const putPrescriptions = async (id, data) => {
  return await updateQuery(`consultations/${id}/prescriptions`, data);
};

export const putPharmacies = async (id, data) => {
  return await updateQuery(`consultations/${id}/pharmacies`, data);
};

export const putLaboratories = async (id, data) => {
  return await updateQuery(`consultations/${id}/laboratories`, data);
};

export const deleteCarePlan = async (id) => {
  return await deleteQuery(`consultations/careplans/${id}`);
};

// call
export const getVideoCall = async (roomId) => {
  return await fetchQuery(`calls/video/${roomId}`, true);
};

export const getAllCalls = async () => {
  const qry = await fetchQuery(`calls`);
  return qry.data.data;
};

export const getVideoCallInvite = async (meetingId, patientId) => {
  return await fetchQuery(`calls/invite/${meetingId}/${patientId}`);
};

export const postNewCall = async (call) => {
  return await postQuery("calls", call);
};

export const deleteExitVideoCall = async (roomId) => {
  return await deleteQuery(`calls/video/${roomId}`);
};

// reports
export const getConsultationsReport = async () => {
  const qry = await fetchQuery(`reports/consultations`);
  return qry.data.data;
};

export const getCallsReport = async () => {
  const qry = await fetchQuery(`reports/calls`);
  return qry.data.data;
};

export const getPrescriptionsReport = async () => {
  const qry = await fetchQuery(`reports/prescriptions`);
  return qry.data.data;
};

export const getLaboratoriesReport = async () => {
  const qry = await fetchQuery(`reports/laboratories`);
  return qry.data.data;
};

export const getDiagnosisReport = async () => {
  const qry = await fetchQuery(`reports/diagnosis`);
  return qry.data.data;
};

// list
export const getAllDrugs = async () => {
  const qry = await fetchQuery(`lists/drugs`);
  return qry.data.data;
};
export const getAllDiagnosis = async () => {
  const qry = await fetchQuery(`lists/diagnosis`);
  return qry.data.data;
};
export const getAllInvestigations = async () => {
  const qry = await fetchQuery(`lists/investigations`);
  return qry.data.data;
};
export const getAllSymptoms = async () => {
  const qry = await fetchQuery(`lists/symptoms`);
  return qry.data.data;
};
export const getAllLabActivities = async () => {
  const qry = await fetchQuery(`lists/lab-activities`);
  return qry.data.data;
};

// post list

export const postNewDrug = async (data) => {
  return await postQuery("lists/drugs", data);
};

export const postNewLabActivity = async (data) => {
  return await postQuery("lists/lab-activities", data);
};

export const postNewDiagnosis = async (data) => {
  return await postQuery("lists/diagnosis", data);
};

// external (service providers)
export const getExtProviderConsultation = async (code) => {
  // const qry = await fetchQuery( `v1/ext/consultations/${ code }/service-provider`, true, true );
  return await fetchQuery(
    `v1/ext/consultations/${code}/service-provider`,
    true,
    true
  );
  // return qry.data.data;
};

export const getExtPatientConsultation = async (code, hashcode) => {
  return await fetchQuery(
    `v1/ext/consultations/${code}/patient/${hashcode}`,
    false,
    true
  );
  // return qry.data.data;
};

//
export const getExtConsultations = async () => {
  // category = pharmacy or laboratory
  // return await fetchQuery( `v1/ext/consultations`, true, true );
  const qry = await fetchQuery(`v1/ext/consultations`, true, true);
  return qry.data.data;
};

export const putLabReport = async (data, config) => {
  return await updateQuery("v1/ext/laboratory", data, true, true, config);
};

export const putIssueMedicine = async (data) => {
  return await updateQuery("v1/ext/pharmacy/issue-medicine/", data, true, true);
};

export const putAddPharmacyNote = async (note) => {
  return await updateQuery("v1/ext/pharmacy/add-notes", note, true, true);
};

export const postExtLogin = async (data) => {
  return await postQuery("v1/ext/auth/login", data, true, true);
};

export const postExtPatientFeedback = async (code, hashcode, data) => {
  return await postQuery(
    `v1/ext/consultations/${code}/patient/${hashcode}`,
    data,
    false,
    true
  );
  // return qry.data.data;
};

// profile
export const getProfileById = async (id) => {
  const qry = await fetchQuery(`accounts/${id}`);
  return qry.data.data;
};

// facility
export const getFacilityById = async (id) => {
  const qry = await fetchQuery(`facilities/${id}`);
  return qry.data.data;
};

export const getAllFacilities = async () => {
  const qry = await fetchQuery(`facilities`);
  return qry.data.data;
};

export const getAllFacilitiesBranches = async () => {
  const qry = await fetchQuery(`facilities/branches`);
  return qry.data.data;
};

export const getFacilitySettings = async (id) => {
  const qry = await fetchQuery(`facilities/${id}/settings`);
  return qry.data.data;
};

export const getFacilityServices = async (id) => {
  const qry = await fetchQuery(`facilities/${id}/services`, true);
  return qry.data.data;
};

export const getFacilityBranchById = async (id) => {
  const qry = await fetchQuery(`facilities/branches/${id}`);
  return qry.data.data;
};

export const getFacilityBranches = async (facilityId) => {
  const qry = await fetchQuery(`facilities/${facilityId}/branches`);
  return qry.data.data;
};

export const postFacilityBranch = async (data) => {
  return await postQuery(`facilities/branches`, data);
};

export const putFacility = async (data) => {
  return await updateQuery(`facilities`, data);
};

export const putFacilitySettings = async (id, data) => {
  return await updateQuery(`facilities/${id}/settings`, data);
};

export const putFacilityBranch = async (id, data) => {
  return await updateQuery(`facilities/branches/${id}`, data);
};

export const putMakeHQ = async (id) => {
  return await updateQuery(`facilities/branches/${id}/make-hq`);
};

export const closeBranch = async (id) => {
  return await deleteQuery(`facilities/branches/${id}`);
};

// expenses
export const getAllExpenses = async () => {
  const qry = await fetchQuery(`expenses`);
  return qry.data.data;
};

export const getExpenseById = async (id) => {
  const qry = await fetchQuery(`expenses/${id}`);
  return qry.data.data;
};

export const postNewExpense = async (data) => {
  return await postQuery(`expenses`, data);
};

export const putExpense = async (data) => {
  return await updateQuery(`expenses`, data);
};

export const deleteExpense = async (id) => {
  return await deleteQuery(`expenses/${id}`);
};

// CATEGORIES
export const getCategory = async (id) => {
  const qry = await fetchQuery(`categories/${id}`);
  return qry.data.data;
};

export const getAllCategories = async (type) => {
  const qry = await fetchQuery(`categories?type=${type}`);
  return qry.data.data;
};

export const postNewCategory = async (data) => {
  return await postQuery("categories", data);
};

export const putCategory = async (data) => {
  return await updateQuery(`categories`, data);
};

export const deleteCategory = async (id) => {
  return await deleteQuery(`categories/${id}`);
};

// ANALYTICS
export const getConsultationAnalytics = async () => {
  const qry = await fetchQuery(`analytics/consultations`);
  return qry.data.data;
};
export const getRevenueAnalytics = async () => {
  const qry = await fetchQuery(`analytics/revenue`);
  return qry.data.data;
};
export const getExpensesAnalytics = async () => {
  const qry = await fetchQuery(`analytics/expenses`);
  return qry.data.data;
};

// public endpoints

// fetch all Facilities
export const getAllPublicFacilities = async () => {
  const qry = await fetchQuery(`v1/facilities`, false, true, {
    headers: {
      sender_id: process.env.REACT_APP_SENDER_ID,
    },
  });
  return qry.data.data;
};

// create attendance (clock-in)
export const postPublicAttendance = async (data) => {
  return await postQuery(`v1/attendance`, data, false, true, {
    headers: {
      sender_id: process.env.REACT_APP_SENDER_ID,
    },
  });
};

// update attendance (clock out)
export const putPublicAttendance = async (data) => {
  return await updateQuery(`v1/attendance`, data, false, true, {
    headers: {
      sender_id: process.env.REACT_APP_SENDER_ID,
    },
  });
};

// post feedback
export const postPublicFeedback = async (data) => {
  return await postQuery(`v1/feedback`, data, false, true, {
    headers: {
      sender_id: process.env.REACT_APP_SENDER_ID,
    },
  });
};

// generic
export const postGenerateOTP = async (key, data) => {
  return await postQuery("accounts/generate-otp", { key, ...data }, true);
};

// memberships
export const postMemberLogin = async (data) => {
  return await postQuery("accounts/member-login", data, true);
};

export const postMembershipSponsor = async (sponsor) => {
  return await postQuery("memberships/sponsors", sponsor);
};

export const putUpdateMembershipSponsor = async (sponsor) => {
  return await updateQuery("memberships/sponsors", sponsor);
};

export const postMembershipRegistration = async (data) => {
  return await postQuery(`memberships`, data, true, false);
};

export const postAuthorizeFacility = async (
  patientId,
  facilityId,
  step,
  otp
) => {
  return await postQuery(
    `patients/${patientId}/authorize-facility/${facilityId}?step=${step}&otp=${otp}`,
    null,
    true
  );
};

export const postMemberReferral = async (patientId, data) => {
  return await postQuery(`patients/${patientId}/referrals`, data, true);
};

export const getMembershipSponsor = async (sponsorId) => {
  const qry = await fetchQuery(`memberships/sponsors/${sponsorId}`);
  return qry.data.data;
};

export const getAllMembers = async (id) => {
  const qry = await fetchQuery(`patients/${id}`);
  return qry.data.data;
};

export const getMembershipSubscription = async (membershipId) => {
  const qry = await fetchQuery(`memberships/${membershipId}`);
  return qry.data.data;
};

export const getAllMembershipPlans = async () => {
  const qry = await fetchQuery(`memberships/plans`);
  return qry.data.data;
};

export const getAllMembershipPlanBenefits = async (planId, identifier) => {
  const qry = await fetchQuery(
    `memberships/plans/${planId}/benefits?identifier=${identifier}`
  );
  return qry.data.data;
};

export const getAllMemberBenefits = async (membershipId) => {
  const qry = await fetchQuery(`memberships/${membershipId}/benefits`);
  return qry.data.data;
};

export const getAllMembershipSponsors = async () => {
  const qry = await fetchQuery("memberships/sponsors");
  return qry.data.data;
};

export const getAllSponsoredMemberships = async (sponsorId) => {
  const qry = await fetchQuery(`memberships/sponsors/${sponsorId}/members`);
  return qry.data.data;
};

//
export const getAllPublicMembershipPlans = async () => {
  const qry = await fetchQuery(`v1/memberships/plans`, true, true, {
    headers: { sender_id: process.env.REACT_APP_SENDER_ID },
  });
  return qry.data.data;
};

export const postPublicMembershipRegistration = async (data) => {
  return await postQuery(`v1/memberships`, data, true, false, {
    headers: { sender_id: process.env.REACT_APP_SENDER_ID },
  });
};
