import { Alert } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { getMember } from '../../../helpers/memberAuth';

const MemberDashboard = ( props ) => {

    return (
        <div>
            <div className="p-4">
                <h2>Hello { getMember()?.firstName || 'there' }</h2>
                <p>Welcome to your care profile</p>

                <Alert icon={ <IconAlertCircle size={ 16 } /> } title="Men At Work!" color="teal">
                    <p>
                        Your care profile is under construction. <br />
                        Kindly check back on February 20th, 2025.</p>
                    <p>Take Care!</p>
                </Alert>
            </div>
        </div >
    );
};

export { MemberDashboard };


