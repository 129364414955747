import React from 'react';
import { Select } from '@mantine/core';

export default function TeamMembersList ( { member, onUpdate, onDelete, plans = [] } ) {
    return (
        <div className='row g-1'>
            <div className="col-2">
                <input
                    type="text"
                    className='input'
                    placeholder='first name'
                    value={ member.firstName }
                    onChange={ ( e ) => onUpdate( { recordId: member.recordId, field: 'firstName', value: e.target.value } ) }
                />
            </div>
            <div className="col-2">
                <input
                    type="text"
                    className='input'
                    placeholder='last name'
                    value={ member.lastName }
                    onChange={ ( e ) => onUpdate( { recordId: member.recordId, field: 'lastName', value: e.target.value } ) }
                />
            </div>
            <div className="col-1">
                <Select
                    id='gender'
                    value={ member?.gender }
                    nothingFound="No match"
                    onChange={ ( value ) => onUpdate( { recordId: member.recordId, field: 'gender', value } ) }
                    size="md"
                    clearable
                    searchable
                    placeholder='gender'
                    data={
                        [
                            { label: 'Male', value: 'Male' },
                            { label: 'Female', value: 'Female' },
                        ]
                    }
                />
            </div>
            <div className="col-2">
                <input
                    type="date"
                    className='input'
                    value={ member.dob }
                    onChange={ ( e ) => onUpdate( { recordId: member.recordId, field: 'dob', value: e.target.value } ) }
                />
            </div>
            <div className="col-2">
                <input
                    type="tel"
                    className='input'
                    placeholder='contact'
                    value={ member.contact }
                    onChange={ ( e ) => onUpdate( { recordId: member.recordId, field: 'contact', value: e.target.value } ) }
                />
            </div>
            <div className="col-2">
                <Select
                    id='planId'
                    nothingFound="No match"
                    value={ member?.planId }
                    onChange={ ( value ) => onUpdate( { recordId: member.recordId, field: 'planId', value } ) }
                    size="md"
                    clearable
                    searchable
                    placeholder='select plan'
                    data={
                        plans?.map( plan => {
                            return {
                                label: plan.planName,
                                value: plan.id
                            };
                        } )
                    }
                />
            </div>

            <div className="col-1 g-0">
                <button className='button is-text'
                    onClick={ () => onDelete( member.recordId ) }
                >
                    <span className="bi bi-trash text-danger"></span>
                </button>
            </div>
        </div>
    );
};;
