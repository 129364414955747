import { useState } from "react";
import { useMutation } from "react-query";
import { postMembershipSponsor } from "../../../helpers/api";
import { message, Select } from "antd";
import _ from "lodash";
import { RequiredIndicator } from "../../../components";

const sponsorTemplate = {
  sponsorName: "",
  sponsorType: "",
  contact: "",
  email: "",
  location: "",
  teamSize: 0,
};

export function NewMembershipSponsor({ onSuccess, isPublic }) {
  const [state, setState] = useState(sponsorTemplate);
  const { Option } = Select;

  const { mutateAsync: createSponsor, isLoading } = useMutation(
    (data) => postMembershipSponsor(state),
    {
      onSuccess: (data, variables, context) => {
        if (data.status === 201) {
          message.success(data.data.message);
          setState(sponsorTemplate);
          onSuccess();
          return;
        }
        throw data;
      },
      onError: (error, variables, context) => {
        const err = error?.response?.data?.message;
        if (_.isArray(err)) {
          err.map((err) => message.error(err.message));
        } else {
          message.error(err || "error processing request!");
        }
      },
    }
  );

  return (
    <div className="row">
      <div className="field col-12">
        <label className="mb-0" htmlFor="sponsorName">
          Sponsor Name
          <RequiredIndicator />
        </label>
        <input
          className="input"
          type="text"
          autoFocus
          value={state?.sponsorName}
          onChange={(e) => setState({ ...state, sponsorName: e.target.value })}
          id="sponsorName"
          key="sponsorName"
          placeholder="e.g. John"
        />
      </div>
      <div className="field col-12">
        <label className="mb-0" htmlFor="sponsorType">
          Sponsor Type
          <RequiredIndicator />
        </label>
        <Select
          required
          allowClear
          showSearch
          onChange={(v) => setState({ ...state, sponsorType: v })}
          value={state?.sponsorType}
          size="large"
          id="sponsorType"
          key="sponsorType"
          className="d-block"
          placeholder="sponsorType"
        >
          <Option value="organization">Organization</Option>
          <Option value="individual">Individual</Option>
        </Select>
      </div>
      <div className="field col-12">
        <label className="mb-0" htmlFor="contact">
          Contact
          <RequiredIndicator />
        </label>
        <input
          className="input"
          type="tel"
          value={state?.contact}
          onChange={(e) => setState({ ...state, contact: e.target.value })}
          id="contact"
          key="contact"
          placeholder="e.g. 0200000000"
        />
      </div>
      <div className="field col-12">
        <label className="mb-0" htmlFor="contact">
          Team Size
          <RequiredIndicator />
        </label>
        <input
          className="input"
          type="number"
          min={1}
          required
          value={state?.teamSize}
          onChange={(e) => setState({ ...state, teamSize: e.target.value })}
          id="teamSize"
          key="teamSize"
          placeholder="4"
        />
      </div>
      <div className="field col-12">
        <label className="mb-0" htmlFor="email">
          Email
          <RequiredIndicator />{" "}
        </label>
        <input
          className="input"
          required
          type="email"
          value={state?.email}
          onChange={(e) => setState({ ...state, email: e.target.value })}
          id="email"
          key="email"
          placeholder="e.g. hr@company.com"
        />
      </div>
      <div className="field col-12">
        <label className="mb-0" htmlFor="location">
          Location
        </label>
        <input
          className="input"
          type="location"
          value={state?.location}
          onChange={(e) => setState({ ...state, location: e.target.value })}
          id="location"
          key="location"
          placeholder="e.g. hr@company.com"
        />
      </div>

      <div className="field mt-3">
        <button
          onClick={createSponsor}
          className={`button is-info ${isLoading && " is-loading"}`}
        >
          <span className="bi bi-check-all me-2" />
          Submit
        </button>
      </div>
    </div>
  );
}
