import { useState } from "react";
import { useQuery } from "react-query";
import { getAllSponsoredMemberships } from "../../../helpers/api";
import { Badge, Chip, Loader, Modal } from "@mantine/core";
import { Divider, Table } from "antd";
import { capitalize } from "lodash";
import { EditPatientForm } from "./edit-patient";

const SponsoredMembers = ({ sponsorId }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [modal, setModal] = useState({
    title: "",
    open: false,
    content: "",
    size: 500,
    zIndex: 55,
  });

  // queries
  const {
    data: sponsoredMembers,
    isFetching,
    refetch,
  } = useQuery({
    queryFn: () => getAllSponsoredMemberships(sponsorId),
    queryKey: ["sponsored-members"],
    onSuccess: (data) => setFilteredData(data),
  });

  // sponsors table
  const columns = [
    {
      title: "Member",
      sorter: (a, b) => a?.firstName > b?.firstName,
      sortDirections: ["descend", "ascend"],
      width: 250,
      render: (text, record, index) =>
        `${record?.patient?.firstName} ${record?.patient?.lastName}`,
    },
    {
      title: "Gender",
      dataIndex: ["patient", "gender"],
      sorter: (a, b) => a?.patient.gender > b?.patient.gender,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Membership ID",
      dataIndex: "membershipId",
      sorter: (a, b) => a?.membershipId > b?.membershipId,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Plan",
      dataIndex: ["plan", "planName"],
      sorter: (a, b) => a?.plan.planName > b?.plan.planName,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Status",
      sorter: (a, b) => a?.isActive > b?.isActive,
      sortDirections: ["descend", "ascend"],
      render: (text, record, index) =>
        record.isActive ? (
          <Badge color="teal" variant="filled">
            Active
          </Badge>
        ) : (
          <Badge color="red" variant="filled">
            InActive
          </Badge>
        ),
    },
    {
      title: "Start Date",
      sorter: (a, b) => a?.startDate > b?.startDate,
      sortDirections: ["descend", "ascend"],
      render: (text, record, index) =>
        new Date(record.startDate).toDateString(),
    },
    {
      title: "-",
      width: 10,
      render: (text, record, index) => (
        <button
          className="button app-btn is-ghost"
          onClick={() => {
            setModal({
              title: "Member Details",
              open: true,
              size: 600,
              content: (
                <EditPatientForm
                  onSuccess={refetch}
                  patientId={record.patient.id}
                  showCharm={false}
                  isEditable
                />
              ),
            });
          }}
        >
          Open
        </button>
      ),
    },
  ];

  return (
    <div>
      <Modal
        title={modal.title}
        opened={modal.open}
        size={modal.size}
        onClose={() => setModal({ ...modal, open: false })}
        zIndex={modal.zIndex}
      >
        {modal.content}
      </Modal>
      <div className="row">
        <div className="col-md-6 col-12">
          <input
            type="text"
            className="input"
            placeholder="search by name, gender, membership id or plan"
            onChange={(e) =>
              setFilteredData(
                sponsoredMembers.filter(
                  (sv) =>
                    sv.patient.firstName
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()) ||
                    sv.patient.lastName
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()) ||
                    sv.patient.gender
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()) ||
                    sv.membershipId
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()) ||
                    sv.plan.planName
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                )
              )
            }
          />
        </div>
      </div>
      <Divider />
      <Chip className="mb-3" disabled>
        showing <strong>{filteredData?.length}</strong> Members
      </Chip>

      {isFetching && <Loader />}
      <Table
        className="border"
        rowKey={(record) => record.id}
        dataSource={filteredData}
        columns={columns}
        loading={isFetching}
        bordered
      />
    </div>
  );
};

export default SponsoredMembers;
