import { message } from 'antd';
import { atom } from 'jotai';
import { nanoid } from 'nanoid';


// state
const _bill = atom( {
    billNumber: '',
    billTo: '',
    billAddress: '',
    billSubject: '',
    discountPercentage: 0,
    note: "",
    dueDate: null,
    patientId: null,
    discount: 0,
    paymentReference: '',
    membershipId: null
    // amount_paid: 0,
} );
const _items = atom( [] );
const _payments = atom( [] );
const _services = atom( [] );


// methods
// export const sumTotal = cedisLocale.format( parseFloat( ( subTotal + totalCharges ) - sale.discount ) )

export const getBillSubTotalAtom = atom( get => {
    let subtotal = 0;
    get( _items ).map( item => {
        const rate = get( _services ).find( s => s.id === item.serviceId )?.baseRate || 0;
        subtotal += ( parseFloat( rate ) * parseInt( item.quantity ) );
    } );

    return subtotal;
} );


export const getTotalPaymentsAtom = atom(
    get => {
        let total = 0;
        get( _payments ).map( c => {
            total += parseFloat( c.amount || 0 );
        } );
        return total;
    }
);

export const getSumAtom = atom( get => {
    let subtotal = 0;

    get( _items ).map( item => {
        const rate = get( _services ).find( s => s.id === item.serviceId )?.baseRate || 0;
        subtotal += ( parseFloat( rate ) * parseInt( item.quantity ) );
    } );

    const discount = parseFloat( get( _bill )?.discount ) || 0;
    return parseFloat( subtotal - discount );
} );


// atoms
export const billAtom = atom(
    ( get ) => get( _bill ),
    ( get, set, bill ) => {
        set( _bill, { ...get( _bill ), ...bill } );
    }
);

export const servicesAtom = atom(
    ( get ) => get( _services ),
    ( get, set, services ) => {
        set( _services, services );
    }
);


export const billItemsAtom = atom(
    ( get ) => get( _items ),
    // ( get, set, item ) => {
    //     const items = get( _items );
    //     const curItem = items.find( it => it.recordId === item.recordId );

    //     if ( curItem )
    //         set( items, items.map( p => {
    //             if ( p.id === item.id ) {
    //                 p.quantity += 1;
    //                 return p;
    //             }
    //             else
    //                 return p;
    //         } ) );
    //     else
    //         set( _items, [ ...items, { recordId: nanoid(), serviceId: null, rate: 0, quantity: 0 } ] );
    // }
);


export const paymentsAtom = atom(
    get => get( _payments ),
    ( get, set, payment ) => {
        set( _payments, [ ...get( _payments ), { recordId: nanoid(), paymentMethod: '', amount: 0 } ] );
    }
);



// selected products handlers
export const addItemAtom = atom(
    null,
    ( get, set, param ) => {
        set( _items, [ ...get( _items ), { recordId: nanoid(), serviceId: null, rate: 0, quantity: 1 } ] );
    } );


export const deleteItemAtom = atom(
    null,
    ( get, set, recordId ) => {
        set( _items, get( _items ).filter( itm => itm.recordId !== recordId ) );
    } );


export const updateItemAtom = atom(
    null,
    ( get, set, params ) => {
        let { recordId, field, value } = params;

        let item = get( _items ).find( pr => pr.recordId === recordId );

        // if ( [ 'rate' ].find( f => f === field ) )
        if ( field === 'quantity' && value < 0 )
            value = 0;

        if ( ( field === 'serviceId' ) && get( _items ).find( itm => itm.serviceId === value ) ) {
            message.error( 'already selected!' );
            return;
        }

        if ( field === 'serviceId' )
            item.rate = get( _services ).find( s => s.id === value )?.baseRate;


        item[ field ] = value;
        set(
            _items,
            get( _items ).map( itm => {
                if ( itm.recordId === recordId )
                    return item;

                return itm;
            } )
        );

    } );



// payments handler
export const deletePaymentAtom = atom(
    null,
    ( get, set, recordId ) => {
        set( _payments, get( _payments ).filter( pay => pay.recordId !== recordId ) );
    } );


export const updatePaymentAtom = atom(
    null,
    ( get, set, params ) => {
        const { recordId, field, value } = params;

        // prevent duplicate methods
        if ( ( field === 'paymentMethod' ) && get( _payments ).find( pay => pay.paymentMethod === value ) ) {
            message.error( 'method already selected!' );
            return;
        }

        const updatedPayment = get( _payments ).find( pay => pay.recordId === recordId );
        updatedPayment[ field ] = value;

        // update payments
        set( _payments,
            get( _payments )
                .map( pay => {
                    if ( pay.recordId === recordId )
                        return updatedPayment;
                    return pay;
                } )
        );
    } );



// get
export const getCompleteBillAtom = atom( get => {

    const bill = get( _bill );
    const payments = get( _payments );
    const items = get( _items );
    // const charges = get( _charges );

    // sale.discount = sale.discount && 0
    return { bill, payments, items };
} );


export const resetBillAtom = atom(
    null,
    ( get, set, params ) => {
        set( _bill, {
            billNumber: '',
            billTo: '',
            billAddress: '',
            billSubject: '',
            discountPercentage: 0,
            note: "",
            dueDate: null,
            patientId: null,
            // amount_paid: 0,
        } );
        set( _items, [] );
        set( _payments, [] );
    } );