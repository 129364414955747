import { useMutation } from "react-query";
import { postPublicMembershipRegistration } from "../../../helpers/api";
import { message, Select } from "antd";
import _ from "lodash";
import { RequiredIndicator } from "../../../components";
import { useAtom } from "jotai";
import { sponsorAtom } from "../../../helpers/state/membership";
import { SPONSOR_TYPES } from "../../../helpers/config";
import PaystackPop from "@paystack/inline-js";

export function NewMembershipRegistration({ onSuccess, onBack }) {
  const [state, setState] = useAtom(sponsorAtom);
  // const [ , clearSponsor ] = useAtom( clearSponsorAtom );
  const { Option } = Select;

  const { mutateAsync: submitRegistration, isLoading } = useMutation(
    (data) => postPublicMembershipRegistration(data),
    {
      onSuccess: (data, variables, context) => {
        if (data.status === 201) {
          //   message.success(data.data.message);
          // clearSponsor();
          onSuccess();

          const payment = data.data.payment;
          if (payment) {
            const popup = new PaystackPop();
            popup.resumeTransaction(payment.access_code);
          }

          return;
        }
        throw data;
      },
      onError: (error, variables, context) => {
        const err = error?.response?.data?.message;
        if (_.isArray(err)) {
          err.map((err) => message.error(err.message));
        } else {
          message.error(err || "error processing request!");
        }
      },
    }
  );

  const handleSignUp = () => {
    // console.log( { state } );
    if (state.teamSize < 1) return message.error("team size is invalid");
    submitRegistration(state);
  };

  return (
    <div className="row">
      {/* <div className="field col-12">
                <label className="mb-0" htmlFor="sponsorType">
                    Who is Registering?
                    <RequiredIndicator />
                </label>
                <Select
                    required
                    allowClear
                    showSearch
                    onChange={ v => setState( { ...state, sponsorType: v } ) }
                    value={ state?.sponsorType }
                    size="large"
                    id="sponsorType"
                    key='sponsorType'
                    className="d-block"
                    placeholder="sponsorType"
                >
                    <Option value={ SPONSOR_TYPES.organization }>Organization</Option>
                    <Option value={ SPONSOR_TYPES.individual }>Individual</Option>
                </Select>
            </div> */}

      <div className="field col-12">
        <label className="mb-0" htmlFor="sponsorName">
          {state.sponsorType === SPONSOR_TYPES.individual
            ? "Full Name"
            : "Organization Name"}
          <RequiredIndicator />
        </label>
        <input
          className="input"
          type="text"
          required
          autoFocus
          value={state?.sponsorName}
          onChange={(e) => setState({ ...state, sponsorName: e.target.value })}
          id="sponsorName"
          key="sponsorName"
          placeholder={`e.g. ${
            state.sponsorType === SPONSOR_TYPES.individual
              ? "John Doe"
              : "Company Ltd"
          }`}
        />
      </div>
      {state.sponsorType === SPONSOR_TYPES.individual && (
        <>
          <div className="field col-12">
            <label className="mb-0" htmlFor="gender">
              Gender
              <RequiredIndicator />
            </label>
            <Select
              required
              allowClear
              showSearch
              onChange={(v) => setState({ ...state, gender: v })}
              value={state?.gender}
              size="large"
              id="gender"
              key="gender"
              className="d-block"
              placeholder="gender"
            >
              <Option value="Male">Male</Option>
              <Option value="Female">Female</Option>
            </Select>
          </div>
          <div className="field col-12">
            <label className="mb-0" htmlFor="dob">
              Date of Birth
              <RequiredIndicator />
            </label>
            <input
              className="input"
              type="date"
              value={state?.dob}
              onChange={(e) => setState({ ...state, dob: e.target.value })}
              id="dob"
              key="dob"
              placeholder="e.g. Madina"
            />
          </div>
        </>
      )}
      <div className="field col-12">
        <label className="mb-0" htmlFor="contact">
          Contact
          <RequiredIndicator />
        </label>
        <input
          className="input"
          required
          type="tel"
          value={state?.contact}
          onChange={(e) => setState({ ...state, contact: e.target.value })}
          id="contact"
          key="contact"
          placeholder="e.g. 0200000000"
        />
      </div>
      <div className="field col-12">
        <label className="mb-0" htmlFor="email">
          Email
          <RequiredIndicator />
        </label>
        <input
          className="input"
          type="email"
          required
          value={state?.email}
          onChange={(e) => setState({ ...state, email: e.target.value })}
          id="email"
          key="email"
          placeholder={`e.g. ${
            state.sponsorType === SPONSOR_TYPES.individual
              ? "me@email.com"
              : "hr@company.com"
          }`}
        />
      </div>
      {state.sponsorType === SPONSOR_TYPES.organization && (
        <>
          <div className="field col-12">
            <label className="mb-0" htmlFor="teamSize">
              Team Size
              <RequiredIndicator />
            </label>
            <input
              className="input"
              type="number"
              required
              min={0}
              value={state?.teamSize}
              onChange={(e) => setState({ ...state, teamSize: e.target.value })}
              id="teamSize"
              key="teamSize"
              placeholder="e.g. 10"
            />
          </div>
          <div className="field col-12">
            <label className="mb-0" htmlFor="location">
              Location
            </label>
            <input
              className="input"
              type="text"
              value={state?.location}
              onChange={(e) => setState({ ...state, location: e.target.value })}
              id="location"
              key="location"
              placeholder="e.g. Madina"
            />
          </div>
        </>
      )}
      {/* <div className="field col-12 mt-2">
                <Button.Group>
                    <Button
                        color='green'
                        onClick={ () => setState( { ...state, payNow: false } ) }
                        variant={ state.payNow === false ? 'filled' : 'outline' }
                    >Later</Button>
                    <Button
                        onClick={ () => setState( { ...state, payNow: true } ) }
                        variant={ state.payNow ? 'filled' : 'outline' } color='green'
                    >Now</Button>
                </Button.Group>
                <p className='text-muted'>When do you want to pay?</p>
            </div> */}
      <div className="d-flex justify-content-between mt-3">
        <button
          disabled={state.payNow === null}
          onClick={handleSignUp}
          className={`button is-info ${isLoading && " is-loading"}`}
        >
          <span className="bi bi-check-all me-2" />
          {state.payNow ? "Submit and Pay" : "Submit"}
        </button>
        <button onClick={onBack} className="button is-light">
          <span className="bi bi-arrow-left me-2" />
          back
        </button>
      </div>
    </div>
  );
}
