import { message } from 'antd';
import { atom } from 'jotai';
import { nanoid } from 'nanoid';

// templates
const carePlanTemplate = {
    assessment: "",
    diagnosis: "",
    plan: "",
    evaluation: "",
    intervention: ""
};
const memberReferralTemplate = {
    id: null,
    referringDiagnosis: "",
    referralReason: "",
    presentingComplain: "",
    examinationFindings: "",
    investigationResults: "",
    treatmentGiven: "",

    patientId: null,
    referringOfficerId: null,
    sourceId: null,
    destinationId: null,
    consultationId: null,
};

const membershipRegistrationTemplate = {
    firstName: "",
    lastName: "",
    gender: "",
    contact: "",
    planId: "",
    planSlug: "",
    dob: null
};

const sponsoredMembersTemplate = {
    sponsorId: null,
};

// state
const _carePlan = atom( carePlanTemplate );
const _memberReferral = atom( memberReferralTemplate );
// const _otpCode = atom( '' );
const _membershipRegistration = atom( membershipRegistrationTemplate );
const _sponsoredMembers = atom( [] );



// atoms

// CAREPLAN
export const carePlanAtom = atom(
    get => get( _carePlan ),
    ( get, set, plan ) => {
        set( _carePlan, { ...get( _carePlan ), ...plan } );
    }
);

export const clearCarePlanAtom = atom(
    null,
    ( get, set, plan ) => {
        set( _carePlan, carePlanTemplate );
    }
);


// export const otpCodeAtom = atom(
//     get => get( _otpCode ),
//     ( get, set, code ) => {
//         set( _otpCode, code );
//     }
// );


// REFERRAL
export const memberReferralAtom = atom(
    get => get( _memberReferral ),
    ( get, set, referral ) => {
        set( _memberReferral, { ...get( _memberReferral ), ...referral } );
    }
);


export const clearRerralAtom = atom(
    null,
    ( get, set, referral ) => {
        set( _memberReferral, memberReferralTemplate );
    }
);


// MEMBERSHIP REGISTRATION
export const membershipRegistrationAtom = atom(
    get => get( _membershipRegistration ),
    ( get, set, registration ) => {
        set( _membershipRegistration, { ...get( _membershipRegistration ), ...registration } );
    }
);


export const clearRegistrationAtom = atom(
    null,
    ( get, set, registration ) => {
        set( _membershipRegistration, membershipRegistrationTemplate );
    }
);



// NEW SPONSORED MEMBERSHIP
export const getMembersAtom = atom(
    ( get ) => get( _sponsoredMembers )
);


export const addMemberAtom = atom(
    null,
    ( get, set, param ) => {
        set( _sponsoredMembers, [
            ...get( _sponsoredMembers ),
            {
                recordId: nanoid(),
                planId: '',
                firstName: '',
                lastName: '',
                dob: null,
                gender: '',
                contact: '',
            } ] );
    } );

export const deleteMemberAtom = atom(
    null,
    ( get, set, recordId ) => {
        set( _sponsoredMembers, get( _sponsoredMembers ).filter( member => member.recordId !== recordId ) );
    } );


export const updateMemberAtom = atom(
    null,
    ( get, set, params ) => {
        let { recordId, field, value } = params;

        let item = get( _sponsoredMembers ).find( pr => pr.recordId === recordId );

        // if ( ( field === 'planId' ) && get( _sponsoredMembers ).find( itm => itm.planId === value ) ) {
        //     message.error( 'already selected!' );
        //     return;
        // }

        item[ field ] = value;
        set(
            _sponsoredMembers,
            get( _sponsoredMembers ).map( itm => {
                if ( itm.recordId === recordId )
                    return item;

                return itm;
            } )
        );

    } );


export const resetSponsoredMembershipAtom = atom(
    null,
    ( get, set, params ) => {
        set( _sponsoredMembers, [] );
    } );