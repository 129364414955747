import { IconBrandYahoo } from "@tabler/icons";
import React from "react";
import { google, ics, outlook, yahoo } from "calendar-link";
import { Divider, message } from "antd";
import { cancelReasons, FEEDBACK } from "../../helpers/config";
import {
  Chip,
  Loader,
  ActionIcon,
  Affix,
  Text,
  Alert,
  Badge,
  Card,
  CardSection,
} from "@mantine/core";
import { useEffect, useState } from "react";
import _ from "lodash";
import { Rating } from "react-simple-star-rating";
import {
  getConsultationFeedback,
  getMembershipSubscription,
} from "../../helpers/api";
import { useMutation, useQuery } from "react-query";
import { IconBell, IconPhone } from "@tabler/icons-react";
import { useAtom } from "jotai";
import { otpCodeAtom } from "../../helpers/state";
import { addDays, differenceInDays } from "date-fns";
import { MemberBenefits } from "../../pages/member/benefits";
import { calcAge, calSubscriptionEndDate } from "../../helpers/utilities";

export const AppVersion = ({ showTitle = true, className }) => {
  return (
    <div className={className}>
      {showTitle && "RiviaOS "} v{process.env.REACT_APP_VERSION}
    </div>
  );
};

export const AddToCalendarComponent = ({ event }) => {
  return (
    <div>
      <p className="text-muted">
        Select your favourite calendar to add appointment schedule to
      </p>
      <div className="buttons">
        <button
          onClick={() => window.open(google(event), "_blank", "noreferrer")}
          className="button w-100"
        >
          <span className="bi bi-google me-2"></span>
          Google Calendar
        </button>
        <button
          onClick={() => window.open(outlook(event), "_blank", "noreferrer")}
          className="button w-100"
        >
          <span className="bi bi-envelope me-2" />
          Outlook Calendar
        </button>
        <button
          onClick={() => window.open(yahoo(event), "_blank", "noreferrer")}
          className="button w-100"
        >
          <IconBrandYahoo className="me-2" />
          Yahoo Calendar
        </button>
        <button
          onClick={() => window.open(ics(event), "_blank", "noreferrer")}
          className="button w-100"
        >
          <span className="bi bi-download me-2"></span>
          Download ICS File
        </button>
      </div>
    </div>
  );
};

export const CopyRightTag = ({ className }) => {
  return <div className={className}>@Rivia {new Date().getUTCFullYear()}</div>;
};
{
  /* <small className='mt-0 pt-0'><AppVersion /></small> */
}

export const DetailTile = ({
  title,
  detail,
  icon,
  className,
  firstCol,
  secondCol,
}) => {
  return (
    <div className={`row ${className}`}>
      <div className={`${firstCol ?? "col-md-3"} col`}>
        <span className={`bi bi-${icon} h5 me-3`}></span>
        <strong>{title}</strong>
      </div>
      <div className={`${secondCol ?? "col-md-9"} col`}>{detail}</div>
    </div>
  );
};

export const CancelAppointment = ({ reason, onUpdate, onCancel, loading }) => {
  return (
    <>
      <div className="row">
        <div className="field col-12">
          <kbd className="bg-danger">
            Cancelling an appointment cannot be undone!
          </kbd>
          <label className="mb-0" htmlFor="cancelReason">
            Cancel Reason
          </label>
          <textarea
            className="textarea"
            id="cancelReason"
            placeholder="reason for cancelling appointment"
            value={reason}
            onChange={(e) => onUpdate(e.target.value)}
          ></textarea>
          <small>why are you cancelling this appointment?</small>
          <Divider />
        </div>
        <div className="mb-3">
          {cancelReasons.map((res) => (
            <Chip
              onClick={() => {
                if (!reason.includes(res))
                  onUpdate(reason ? `${reason} - ${res}` : res);
              }}
              className="d-inline-block mb-1"
              title={res}
            >
              {res}
            </Chip>
          ))}
        </div>
      </div>
      <button
        className={`button is-danger mt-2 ${loading && " is-loading"}`}
        onClick={onCancel}
      >
        Cancel
      </button>
    </>
  );
};

export const FeedBackForm = ({ onSubmit, isLoading }) => {
  const [state, setState] = useState({ rating: 0, reasons: [], comments: "" });

  const handleAddReason = (reason) => {
    if (state.reasons.includes(reason))
      setState({
        ...state,
        reasons: state.reasons.filter((r) => r !== reason),
      });
    else setState({ ...state, reasons: [...state.reasons, reason] });
  };

  return (
    <div className="row">
      <h4>Rate Your Experience</h4>
      <div className="row">
        <label className="mb-0 label mb-2" htmlFor="comments">
          How do you feel about the service?
        </label>
        <div className="col-12">
          <Rating
            size={50}
            initialValue={state.rating}
            onClick={
              (value) =>
                value !== state.rating &&
                setState({ ...state, reasons: [], rating: parseInt(value) })
              // setState( { ...state, rating: parseInt( value ) } )
            }
            // allowFraction
            showTooltip
            tooltipArray={[
              "Terrible",
              // 'Terrible +',
              "Average",
              // 'Average +',
              "Good",
              // 'Good +',
              "Awesome",
              // 'Awesome +',
              "Wow",
              // 'Wow +'
            ]}
          />
        </div>
      </div>
      {state.rating && (
        <div className="row">
          <Divider />
          <label className="mb-0 label mb-2" htmlFor="">
            What is the reason?
          </label>
          {FEEDBACK.reasons
            .filter((res) => res.rate === state.rating)
            .map((res) => (
              <div className="col mb-2" key={res.value}>
                <Chip
                  size="md"
                  checked={state.reasons.includes(res.value)}
                  onClick={() => handleAddReason(res.value)}
                >
                  {res.reason}
                </Chip>
              </div>
            ))}
        </div>
      )}
      <Divider />
      <div className="row">
        <div className="col-12">
          <div className="field">
            <label className="mb-0 label" htmlFor="comments">
              We love to know more
            </label>
            <textarea
              className="textarea"
              name="comments"
              id="comments"
              onChange={(e) => setState({ ...state, comments: e.target.value })}
              value={state.comments}
              placeholder="leave your comments here"
            ></textarea>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <button
          onClick={() =>
            onSubmit({ ...state, reasons: state.reasons.toString() })
          }
          className={`button h6 app-btn btn-prim ${isLoading && " is-loading"}`}
        >
          <span className="bi bi-check-circle me-2" />
          Submit Feedback
        </button>
      </div>
    </div>
  );
};

export const FeedBackView = ({ consultationId }) => {
  const {
    data: state = {},
    isLoading,
    refetch,
  } = useQuery({
    queryFn: () => getConsultationFeedback(consultationId),
    queryKey: ["consultation-feedback", consultationId],
  });

  return (
    <div className="row">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <h4>Patient Feedback</h4>
          <div className="row">
            <label className="mb-0 label mb-2" htmlFor="">
              Rating
            </label>
            <div className="col-12">
              <Rating
                size={50}
                disableFillHover
                initialValue={state.rating}
                showTooltip
                tooltipArray={[
                  "Terrible",
                  // 'Terrible +',
                  "Average",
                  // 'Average +',
                  "Good",
                  // 'Good +',
                  "Awesome",
                  // 'Awesome +',
                  "Wow",
                  // 'Wow +'
                ]}
              />
            </div>
          </div>
          {state.reasons && (
            <div className="row">
              <Divider />
              <div className="col-12">
                <label className="mb-0 label mb-2" htmlFor="comments">
                  Reasons
                </label>
                <ol>
                  {state?.reasons?.split(",").map((reason, index) => (
                    <li>
                      {_.upperCase(reason).replace("-", " ").replace("_", " ")}
                    </li>
                  ))}
                </ol>
              </div>
              {/* {
                        FEEDBACK.reasons
                            .filter( res => res.rate === state.rating )
                            .map( res =>
                                <div className="col mb-2" key={ res.value }>
                                    <Chip size='md'
                                        onClick={ () => null }
                                        checked={ state.reasons.includes( res.value ) }
                                    >
                                        { res.reason }
                                    </Chip>
                                </div>
                            )
                    } */}
            </div>
          )}
          <Divider />
          <div className="row">
            <div className="col-12">
              <div className="field">
                <label className="mb-0 label" htmlFor="comments">
                  Comments
                </label>
                <textarea
                  className="textarea"
                  name="comments"
                  id="comments"
                  disabled
                  value={state.comments}
                  placeholder="patient comments"
                ></textarea>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const RatingDisplay = ({ rate, total = 5, className }) => {
  return (
    <span className={`${className}`}>
      {rate > 0 ? (
        <div className="d-flex align-items-center">
          <h5 className="mb-0">{rate}</h5>
          <small>/{total}</small>
        </div>
      ) : (
        <span>-</span>
      )}
    </span>
  );
};

export const ActionButton = ({ onClick }) => {
  return (
    <>
      <Affix onClick={onClick} position={{ bottom: 20, right: 20 }}>
        <ActionIcon color="blue" radius="xl" size={60}>
          <IconBell stroke={1.5} size={30} />
        </ActionIcon>
      </Affix>
    </>
  );
};

export const OTPConfirmation = ({
  onSubmit,
  isLoading,
  label,
  placeholder,
}) => {
  const [otp, setOTP] = useState("");

  return (
    <div>
      <label className="label">{label || "Confirm OTP Code"}</label>
      <input
        type="text"
        className="input"
        placeholder={placeholder || "confirm the OTP code sent"}
        onChange={(e) => setOTP(e.target.value)}
      />
      <button
        onClick={(e) => {
          e.preventDefault();
          onSubmit(otp);
        }}
        className={`button mt-3 h6 app-btn btn-prim py-3 px-2 w-100  ${
          isLoading && "is-loading"
        }`}
      >
        Confirm
      </button>
    </div>
  );
};

export const MembershipStatus = ({ membershipId }) => {
  const [memberId, setMemberId] = useState("");
  const [benefits, setBenefits] = useState("");

  const {
    data: sub = {},
    isLoading,
    refetch,
  } = useQuery({
    queryFn: () => getMembershipSubscription(memberId),
    queryKey: ["subscription", memberId],
    enabled: false,
  });

  useEffect(() => {
    if (membershipId) {
      setMemberId(membershipId);
      refetch();
    }
  }, [membershipId]);

  return (
    <>
      {_.isEmpty(sub) && (
        <div className="row">
          <div className="col text-center">
            <label className="label">Membership ID</label>
            <input
              autoFocus
              type="text"
              className="input w-50 text-center"
              placeholder="input Membership ID here"
              value={memberId}
              onChange={(e) => setMemberId(e.target.value)}
            />
            <div>
              <button
                onClick={() => {
                  memberId
                    ? refetch()
                    : message.error("Membership ID required");
                }}
                className={`button mt-3 h6 app-btn btn-prim py-3 px-2 w-25 ${
                  isLoading && " is-loading"
                }`}
              >
                Check Status
              </button>
            </div>
          </div>
        </div>
      )}
      {!_.isEmpty(sub) && (
        <div>
          <Alert color={sub?.isActive ? "teal" : "red"} variant="filled">
            <h5 className="text-white m-0 d-flex justify-content-between align-items-center">
              {`${sub?.patient?.firstName} ${sub?.patient?.lastName}`}
              <Badge color={sub?.isActive ? "teal" : "red"} className="ms-2">
                {sub?.isActive ? "Active" : "InActive"}
              </Badge>
            </h5>
          </Alert>
          <Alert color={sub?.isActive ? "teal" : "red"}>
            <table>
              <tr>
                <th>ID:</th>
                <td className="ps-4">{sub?.membershipId}</td>
              </tr>
              <tr>
                <th>Plan:</th>
                <td className="ps-4">{sub?.plan?.planName}</td>
              </tr>
              <tr>
                <th>Term:</th>
                <td className="ps-4">{_.capitalize(sub?.cycle || "N/A")}</td>
              </tr>
              <tr>
                <th>Since:</th>
                <td className="ps-4">
                  {`${new Date(
                    sub?.startDate
                  ).toDateString()} - (${differenceInDays(
                    new Date(),
                    new Date(sub?.startDate)
                  )} days ago)`}
                </td>
              </tr>
              <tr>
                <th>Expiring on:</th>
                <td className="ps-4">
                  {new Date(
                    calSubscriptionEndDate(sub?.cycle, sub?.startDate)
                  ).toDateString()}
                </td>
              </tr>
            </table>
            {sub?.sponsor && (
              <>
                <Divider />
                <>
                  <Badge color="cyan" variant="filled">
                    Sponsor
                  </Badge>
                  <p className="h6 pt-2 pb-0 mb-0">
                    {sub?.sponsor?.sponsorName}
                  </p>
                  <small>{sub?.sponsor?.sponsorType}</small>
                </>
              </>
            )}
            {!_.isEmpty(benefits) && (
              <div>
                <Badge color="cyan" variant="filled" className="mt-4 mb-2">
                  Benefits
                </Badge>
                <MemberBenefits planId={sub?.plan?.id} showHeader={false} />
              </div>
            )}
          </Alert>

          <div className="flex">
            <button
              className="button app-btn is-ghost mt-3 align-self-end"
              onClick={() => setMemberId(null)}
            >
              <span className="bi bi-arrow-left me-2"></span>
              back
            </button>
            <button
              className="button app-btn is-ghost mt-3 align-self-end"
              onClick={() => setBenefits(benefits ? null : sub?.plan?.id)}
            >
              {benefits ? (
                <span className="bi bi-eye-slash me-2" />
              ) : (
                <span className="bi bi-eye me-2" />
              )}
              {benefits ? "hide " : "show "} benefits
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export const LinkBillToConsultation = ({ onLink }) => {
  const [code, setCode] = useState("");
  const [isLoading, setLoading] = useState(false);

  return (
    <div className="row">
      <div className="col-12 text-center mt-3">
        <label className="mb-0 label" htmlFor="title">
          Enter consultation code
        </label>
        <div className="field">
          <input
            className="w-75 input text-center mt-2"
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            autoFocus
            id="title"
            placeholder="code here"
          />
        </div>
        <button
          onClick={() => {
            if (code === "") {
              message.error("code required");
              return;
            }
            setLoading(true);
            onLink(code);
          }}
          className={`mt-3 button h6 app-btn btn-prim ${
            isLoading && " is-loading"
          }`}
        >
          <span className="bi bi-link-45deg me-2" />
          Link
        </button>
      </div>
    </div>
  );
};

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback;
    }

    return this.props.children;
  }
}
