import { Tabs } from 'antd';

import { SingleRegistration } from './single';
import { TeamRegistration } from './team';


const MembershipRegistrationIndex = ( props ) => {

    const { TabPane } = Tabs;


    return (
        <div>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Single" key="1">
                    <SingleRegistration />
                </TabPane>
                <TabPane tab="Team" key="2">
                    <TeamRegistration />
                </TabPane>
            </Tabs>
        </div >
    );

};

export { MembershipRegistrationIndex };


