import React from "react";
import { useRoutes } from "react-router-dom";

//pages
import {
  LoginPage,
  SignupPage,
  ChangePasswordPage,
  NotFoundPage,
  HomeIndex,
  CareIndex,
  ProfilePage,
  PatientsIndex,
  MembersIndex,
  InBoundCall,
  // AppointmentStatus,
  HRIndex,
  SettingsIndex,
  ServicesIndex,
  AppPage,
  AccountsPage,
  ServiceProviders,
  HospitalPage,
  ForgotPasswordPage,
  VideoCallPage,
  PatientVideoPage,
  StartConsultation,
  NewConsultation,
  LabsPortal,
  ReportingIndex,
  PharmacyPortal,
  ExternalLoginPage,
  PatientPortal,
  RolesPage,
  AccessDenied,
  FacilityIndex,
  SettingsPage,
  LogsPage,
  FacilityAdminPage,
  FinancePage,
  AboutPage,
  SwitchBranch,
  ExternalAttendancePage,
  MemberDashboard,
  NetworkIndex,
  PublicMembershipRegistrationIndex,
  PublicRegistrationThankYouPage
  // FeedBackForm,
} from "./pages";

// components
import { AppLayout, CarePortalLayout, ExternalLayout } from "./components";

// configs
import { appLinks } from "./helpers/config";
import { hasPermission } from "./helpers/utilities";
import { action, resouces } from "./helpers/auth";

const AppRoutes = () => {
  return useRoutes( [
    // no admin template

    {
      path: appLinks.about,
      element: <AppLayout />,
      children: [ { index: true, element: <AboutPage /> } ],
    },
    {
      path: appLinks.switchBranch,
      element: <SwitchBranch />,
      // children: [ { index: true, element: <AboutPage /> } ],
    },
    {
      path: appLinks.attendance,
      element: <ExternalAttendancePage />,
      // children: [ { index: true, element: <FeedBackForm /> } ],
    },

    {
      // admin video call
      path: appLinks.video.room( ":roomId" ),
      element: <VideoCallPage />,
    },
    {
      // patient video call
      path: appLinks.patientVideo.room( ":roomId" ),
      element: <PatientVideoPage />,
    },
    {
      // clinic
      path: appLinks.facility.index,
      element: <AppLayout />,
      children: [
        { index: true, element: <FacilityIndex /> },
        {
          path: appLinks.facility.hr,
          element: < HRIndex />,
          // hasPermission ( resouces.staffs, action.canRead )
          // ? < StaffsIndex /> : <AccessDenied />
        },
        {
          path: appLinks.facility.serviceProviders,
          element: <ServiceProviders />,
          //  hasPermission ( resouces.staffs, action.canRead )
          //   ? < ServiceProviders /> : <AccessDenied />
        },
        {
          path: appLinks.facility.admin,
          element: <FacilityAdminPage />,
        },
        {
          path: appLinks.facility.finance,
          element: <FinancePage />,
        },

      ],
    },
    // {
    //   // Consultation Status
    //   path: appLinks.care.status( ":consultationKey" ),
    //   element: <AppointmentStatus />,
    // },
    //
    {
      // Home
      path: appLinks.home.index,
      element: <AppLayout />,
      children: [ { index: true, element: <HomeIndex /> } ],
    },
    {
      // patient-finder
      path: appLinks.startConsultation.index,
      element: <AppLayout />,
      children: [ { index: true, element: <StartConsultation /> } ],
    },
    // {
    //   // call
    //   path: appLinks.inboundCall.index,
    //   element: <AppLayout />,
    //   children: [ {
    //     index: true,
    //     element: hasPermission( resouces.calls, action.canCreate )
    //       ? <InBoundCall /> : <AccessDenied />
    //   } ],
    // },
    {
      // reporting
      path: appLinks.catalog.reports,
      element: <AppLayout />,
      children: [ {
        index: true,
        element: hasPermission( resouces.reports, action.canRead )
          ? <ReportingIndex /> : <AccessDenied />
      } ],
    },
    {
      // consultation
      path: appLinks.care.index,
      element: <AppLayout />,
      children: [
        {
          index: true,
          element: hasPermission( resouces.consultations, action.canRead ) ?
            <CareIndex /> : <AccessDenied />
        },
        {
          path: appLinks.care.new,
          element: hasPermission( resouces.consultations, action.canCreate ) ?
            <NewConsultation /> : <AccessDenied />
        }
      ],
    },
    {
      // Patients
      path: appLinks.catalog.members,
      element: <AppLayout />,
      children: [
        {
          index: true,
          element: hasPermission( resouces.patients, action.canRead ) ?
            <MembersIndex /> : <AccessDenied />
        } ],
    },
    // {
    //   // Staffs
    //   path: appLinks.catalog.staffs,
    //   element: <AppLayout />,
    //   children: [
    //     {
    //       index: true,
    //       element: hasPermission( resouces.staffs, action.canRead )
    //         ? < StaffsIndex /> : <AccessDenied />
    //     } ],
    // },


    // externals
    {
      // path: appLinks.exterals.index,
      children: [
        {
          path: appLinks.exterals.laboratory,
          element: <ExternalLayout />,
          children: [ {
            index: true,
            element: <LabsPortal />
          } ],
        },
        {
          path: appLinks.exterals.pharmacy,
          element: <ExternalLayout />,
          children: [ {
            index: true,
            element: <PharmacyPortal />
          } ],
        },
        {
          path: appLinks.exterals.patientPortal( ":hash" ),
          element: <PatientPortal />,
        },
        {
          path: appLinks.exterals.login,
          element: <ExternalLoginPage />,
        },
        {
          path: appLinks.exterals.passwordReset,
          element: <ForgotPasswordPage />,
        },
      ]
    },


    {
      // Profile
      path: appLinks.profile.index,
      element: <AppLayout />,
      children: [ { index: true, element: <ProfilePage /> } ],
    },

    // setup
    {
      path: appLinks.setup.index,
      element: <AppLayout />,
      children: [
        { index: true, element: <SettingsIndex /> },
        {
          path: appLinks.setup.accounts,
          element: hasPermission( resouces.users, action.canRead )
            ? <AccountsPage /> : <AccessDenied />
        },
        {
          path: appLinks.setup.logs, element: <LogsPage />
        },
        {
          path: appLinks.setup.settings, element: <SettingsPage />
        },
      ],
    },
    // {
    //   path: appLinks.setup.roles,
    //   element: <AppLayout />,
    //   children: [ { index: true, element: <RolesPage /> } ],
    // },
    // {
    //   path: appLinks.setup.services,
    //   element: <AppLayout />,
    //   children: [ { index: true, element: <ServicesIndex /> } ],
    // },
    // {
    //   path: appLinks.setup.appSettings,
    //   element: <AppLayout />,
    //   children: [ { index: true, element: <AppPage /> } ],
    // },
    // {
    //   path: appLinks.setup.accounts,
    //   element: <AppLayout />,
    //   children: [
    //     {
    //       index: true,
    //       element: hasPermission( resouces.users, action.canRead )
    //         ? <AccountsPage /> : <AccessDenied />
    //     }
    //   ],
    // },
    {
      path: appLinks.setup.serviceProviders,
      element: <AppLayout />,
      children: [
        {
          index: true,
          element: hasPermission( resouces.serviceProviders, action.canRead ) ?
            <ServiceProviders /> : <AccessDenied />
        } ],
    },
    // {
    //   path: appLinks.setup.hospital,
    //   element: <AppLayout />,
    //   children: [ { index: true, element: <HospitalPage /> } ],
    // },

    // auth
    {
      path: appLinks.login,
      index: true,
      element: <LoginPage />,
    },
    {
      path: appLinks.signup,
      element: <SignupPage />,
    },
    {
      path: appLinks.changePassword( ":token" ),
      element: <ChangePasswordPage />,
    },
    {
      path: appLinks.forgotPassword,
      element: <ForgotPasswordPage />,
    },
    {
      path: appLinks.healthPassport,
      element: <CarePortalLayout />,
      children: [
        {
          index: true,
          element: <MemberDashboard />,
        } ],
    },
    {
      path: appLinks.publicMembershipRegistration,
      element: <PublicMembershipRegistrationIndex />,
      index: true,
    },
    {
      path: appLinks.publicRegistrationComplete,
      element: <PublicRegistrationThankYouPage />,
      index: true,
    },
    {
      path: appLinks.network.index,
      element: <AppLayout />,
      children: [
        {
          index: true,
          element: <NetworkIndex />,
        } ],
    },
    {
      element: <NotFoundPage />,
      path: appLinks.catchAll,
      // element: <AppLayout />,
      // children: [
      //   {
      //     index: true,
      //     element: <NotFoundPage />,
      //   } ],
    },
  ] );
};

export default AppRoutes;
