import { Badge, Menu, Modal } from "@mantine/core";
import { BackTop, Table, Input, Space, Select, message } from "antd";
import { useEffect, useState } from "react";
import { MembershipStatus, OTPConfirmation } from "../../components";
import { deletPatient, postAuthorizeFacility } from "../../helpers/api";
import { calcAge, hasPermission, setState } from "../../helpers/utilities";
import { EditPatientForm } from "../index";
import smalltalk from "smalltalk";
import MedicalHistory from "../../components/shared/medical-history";
import PatientJourney from "../../components/shared/patient-journey";
import { action, getUser, resouces } from "../../helpers/auth";
import { IconDotsCircleHorizontal } from "@tabler/icons";

const filters = {
  reset: "reset",
  paidMembers: "paidMembers",
  freeMembers: "freeMembers",
  globalMembers: "globalMembers",
  localMembers: "localMembers",
  genderMale: "genderMale",
  genderFemale: "genderFemale",
};

const PatientsIndex = ({ patients, isFetching, onReload }) => {
  // const [ isNewObject, setIsNewObject ] = useState( false ); //newObject
  const [busy, setBusy] = useState(false);
  const [filter, setFilter] = useState(false);
  const [showingPaid, setShowingPaid] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [modal, setModal] = useState({
    title: "",
    open: false,
    content: "",
    size: 700,
  });

  // const { data: patients, isFetching, refetch: fetchPatients } = useQuery( {
  //   queryFn: getAllPatients,
  //   enabled: false,
  //   queryKey: [ 'patients' ],
  //   onSuccess: ( data ) => {
  //     setFilteredData( data );
  //   }
  // } );

  useEffect(() => {
    setFilteredData(patients);
  }, [patients]);

  const { Search } = Input;
  const { Option } = Select;

  const handleDeletePatient = (id) => {
    smalltalk
      .confirm(
        "Delete Member ",
        "This action cannot be undone. Delete Member?",
        {
          buttons: {
            ok: "YES",
            cancel: "NO",
          },
        }
      )
      .then((go) => {
        // delete patient api call
        deletPatient(id).then((res) => {
          if (res.status === 204) {
            message.success("Member deleted");
            onReload();
            return;
          }

          message.error(res.response.data.message);
        });
      })
      .catch((ex) => {
        // console.log(ex);
        message.error("error deleting member");
        return false;
      });
  };

  const confirmAuthorization = (patientId, otp) => {
    setBusy(true);

    postAuthorizeFacility(patientId, getUser().facility.id, "confirm", otp)
      .then((res) => {
        if (res.status === 201) {
          message.success("Confirmed!");
          setModal({ open: false });
          onReload();
          return;
        }

        message.error(res.message);
      })
      .catch((ex) => {
        // console.log( ex );
        message.error(ex.response.message);
      })
      .finally(() => {
        setBusy(false);
      });
  };

  const handleAccessRequest = (patientId) => {
    smalltalk
      .confirm(
        "Request Access",
        "This will ask member for authorization to their medical records. Continue?",
        {
          buttons: {
            ok: "YES",
            cancel: "NO",
          },
        }
      )
      .then((go) => {
        postAuthorizeFacility(
          patientId,
          getUser().facility.id,
          "authorize"
        ).then((res) => {
          if (res.status === 201)
            setModal({
              content: (
                <OTPConfirmation
                  isLoading={busy}
                  onSubmit={(otp) => confirmAuthorization(patientId, otp)}
                />
              ),
              open: true,
              title: "Authorize Facility",
            });

          message.error(res.response.data.message);
        });
      })
      .catch((ex) => {
        message.error(ex.response.data.message);
        return false;
      });
  };

  const handleSetFilter = (filterName) => {
    switch (filterName) {
      case filters.reset:
        setFilter(false);
        setFilteredData(patients);
        break;
      case filters.paidMembers:
        setFilter(true);
        setFilteredData(patients.filter((p) => p.membershipSubscription));
        break;
      case filters.freeMembers:
        setFilter(true);
        setFilteredData(patients.filter((p) => !p.membershipSubscription));
        break;
      case filters.globalMembers:
        setFilter(true);
        setFilteredData(patients);
        break;
      case filters.localMembers:
        setFilter(true);
        setFilteredData(
          patients.filter((patient) =>
            patient.facilities.some(
              (facility) => facility.facilityId === getUser().facility.id
            )
          )
        );
        break;
      case filters.genderMale:
        setFilter(true);
        setFilteredData(patients.filter((d) => d.gender === "Male"));
        break;
      case filters.genderFemale:
        setFilter(true);
        setFilteredData(patients.filter((d) => d.gender === "Female"));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    onReload();
  }, []);

  // sales table
  const columns = [
    {
      title: "Name",
      sorter: (a, b) => a?.firstName > b?.firstName,
      sortDirections: ["descend", "ascend"],
      render: (text, record, index) =>
        `${record?.firstName}  ${record?.lastName}`,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: (a, b) => a?.gender > b?.gender,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Age",
      sorter: (a, b) =>
        new Date(a?.dob).getFullYear() > new Date(b?.dob).getFullYear(),
      sortDirections: ["descend", "ascend"],
      render: (t, r, i) => calcAge(r?.dob).age,
    },
    {
      title: "Contact",
      dataIndex: "contact",
      sorter: (a, b) => a?.contact > b?.contact,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Plan",
      dataIndex: ["membershipSubscription", "plan", "planName"],
      sorter: (a, b) =>
        a?.membershipSubscription?.plan?.planName >
        b?.membershipSubscription?.plan?.planName,
      sortDirections: ["descend", "ascend"],
      render: (text, record, index) =>
        !record?.membershipSubscription ? (
          <Badge>Free</Badge>
        ) : record?.membershipSubscription.isActive === false ||
          new Date(record?.membershipSubscription.endDate) < new Date() ? (
          <Badge color="red">
            {record?.membershipSubscription.plan?.planName} (InActive)
          </Badge>
        ) : (
          <Badge color="teal">
            {record?.membershipSubscription.plan?.planName}
          </Badge>
        ),
    },
    {
      title: "-",
      width: 40,
      render: (text, record, index) => (
        <Menu shadow="md" width={160}>
          <Menu.Target>
            {/* <button className="button is-small is-ghost px-2">
              <span className="bi bi-list me-2"></span>
            </button> */}
            <button className="button app-btn is-ghost">
              <IconDotsCircleHorizontal />
            </button>
          </Menu.Target>

          <Menu.Dropdown>
            {hasPermission(resouces.patients, action.canUpdate) && (
              <Menu.Item
                icon={<span className="bi bi-eye" />}
                color="blue"
                onClick={() => {
                  setModal({
                    title:
                      "Editing " + record.firstName + " " + record.lastName,
                    open: true,
                    content: (
                      <EditPatientForm
                        patientId={record.id}
                        onSuccess={onReload}
                        showCharm={false}
                      />
                    ),
                    size: 600,
                  });
                }}
              >
                <span className="ms-1">Open</span>
              </Menu.Item>
            )}
            {hasPermission(resouces.patients, action.canRead) && (
              <>
                <Menu.Item
                  icon={<span className="bi bi-signpost" />}
                  onClick={() =>
                    setModal({
                      title: "Journey",
                      open: true,
                      content: <PatientJourney patientId={record.id} />,
                      size: 1200,
                    })
                  }
                >
                  <span className="ms-1">Journey</span>
                </Menu.Item>
                <Menu.Item
                  icon={<span className="bi bi-journal-medical" />}
                  onClick={() =>
                    setModal({
                      title: "Medical History",
                      open: true,
                      content: (
                        <MedicalHistory
                          patientId={record.id}
                          isEditable={false}
                        />
                      ),
                    })
                  }
                >
                  <span className="ms-1">Medical History</span>
                </Menu.Item>
              </>
            )}
            {hasPermission(resouces.patients, action.canUpdate) && (
              <Menu.Item
                icon={<span className="bi bi-unlock" />}
                onClick={() => handleAccessRequest(record.id)}
              >
                <span className="ms-1">Request Access</span>
              </Menu.Item>
            )}
            <Menu.Item
              icon={<span className="bi bi-check-square" />}
              onClick={() =>
                setModal({
                  title: "Membership Status",
                  open: true,
                  content: (
                    <MembershipStatus
                      membershipId={
                        record?.membershipSubscription?.membershipId
                      }
                    />
                  ),
                  size: 600,
                })
              }
            >
              <span className="ms-1">Check Plan</span>
            </Menu.Item>
            {hasPermission(resouces.patients, action.canDelete) && (
              <Menu.Item
                icon={<span className="bi bi-trash" />}
                color="red"
                onClick={() => handleDeletePatient(record.id)}
              >
                Delete
              </Menu.Item>
            )}
          </Menu.Dropdown>
        </Menu>
      ),
    },
  ];

  return (
    <section>
      <Modal
        title={modal.title}
        opened={modal.open}
        size={modal.size}
        onClose={() => setModal({ ...modal, open: false })}
      >
        {modal.content}
      </Modal>
      {/* <PageHeader
        title="Patients"
        hasBackButton
        description="List of all patients attended to"
      >
        {
          hasPermission( resouces.patients, action.canCreate ) &&
          <button
            class="app-btn btn-prim"
            onClick={ () => setIsNewObject( true ) }
          >
            <span className="bi bi-plus-circle me-2"></span>
            <strong className="text-white">NEW</strong>
          </button>
        }
      </PageHeader> */}
      {/* <Divider className="mt-1" /> */}

      {/* <FilterCard filter="filter" className="d-inline-block me-2" /> */}
      {/* <p>
        Showing <strong>{ filteredData.length }</strong>{ " " }
        { filteredData.length > 1 ? " records" : "record" }
      </p> */}
      <div className="my-3 d-flex justify-content-between">
        <Space className="ms-0">
          <input
            className="input"
            onChange={(e) =>
              setFilteredData(
                patients.filter(
                  (p) =>
                    p.firstName
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()) ||
                    p.lastName
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()) ||
                    p.gender
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()) ||
                    p.contact
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()) ||
                    p?.membershipSubscription?.plan?.planName
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                )
              )
            }
            title="start typing to filter"
            placeholder="start typing to filter"
            style={{ minWidth: "30vw" }}
          />
          {/* <Select
            style={{ minWidth: "100px" }}
            size="large"
            name="byType"
            allowClear
            showSearch
            onChange={(v) =>
              v != null
                ? setFilteredData(filteredData.filter((d) => d.gender === v))
                : setFilteredData(patients)
            }
            defaultValue="select gender"
          >
            {[...new Set(filteredData?.map((d) => d.gender))]?.map((v) => (
              <Option value={v} key={v}>
                {v}
              </Option>
            ))}
          </Select> */}
          {/* <button
            className="app-btn"
            onClick={() => {
              showingPaid
                ? setFilteredData(patients)
                : setFilteredData(
                    patients?.filter((pa) => pa.membershipSubscription)
                  );

              setShowingPaid(!showingPaid);
            }}
          >
            <span className="bi bi-cycle"></span>
            <span className="d-none d-md-inline">
              {showingPaid ? "Show All Members" : "Show Paid Members"}
            </span>
          </button> */}
        </Space>
        {/* <button
          onClick={ () => setState( setFilteredData, patients ) }
          className="app-btn btn-text ms-5 d-flex align-items-center"
        >
          <span className="bi bi-arrow-clockwise me-2"></span>
          Reset filters
        </button> */}

        <div className="d-flex">
          <button className="app-btn btn-text" onClick={onReload}>
            <span className="bi bi-cycle"></span>
            <span className="d-none d-md-inline">Refresh</span>
          </button>

          <Menu
            trigger="hover"
            withArrow
            transition="scale-y"
            shadow="md"
            position="bottom-right"
            width={220}
            className="d-block"
          >
            <Menu.Target>
              <button
                className={`app-btn ${
                  filter && " btn-prim"
                } ms-2 d-flex align-items-center`}
              >
                <span
                  className={`bi ${
                    filter ? "bi-funnel-fill" : "bi-funnel"
                  } me-2`}
                />
                <span className="d-none d-md-inline">
                  {filter ? `${filteredData?.length} Filtered` : "Filter"}
                </span>
              </button>
            </Menu.Target>

            <Menu.Dropdown ml={50}>
              <Menu.Item onClick={() => handleSetFilter(filters.reset)}>
                <span className="bi bi-arrow-clockwise me-2" />
                Reset Filters
              </Menu.Item>
              <Menu.Divider />
              <Menu.Label>Memberships</Menu.Label>
              {/* <Menu.Item>All Members</Menu.Item> */}
              <Menu.Item onClick={() => handleSetFilter(filters.paidMembers)}>
                <span className="bi bi-check-all me-2" />
                Paid Membership
              </Menu.Item>
              <Menu.Item onClick={() => handleSetFilter(filters.freeMembers)}>
                <span className="bi bi-ban me-2" />
                Free Membership
              </Menu.Item>
              <Menu.Divider />
              <Menu.Label>Scope</Menu.Label>
              <Menu.Item onClick={() => handleSetFilter(filters.globalMembers)}>
                <span className="bi bi-globe me-2" />
                Global Members
              </Menu.Item>
              <Menu.Item onClick={() => handleSetFilter(filters.localMembers)}>
                <span className="bi bi-geo me-2" />
                Local Members
              </Menu.Item>
              <Menu.Label>Gender</Menu.Label>
              <Menu.Item onClick={() => handleSetFilter(filters.genderMale)}>
                <span className="bi bi-gender-male me-2" />
                Male
              </Menu.Item>
              <Menu.Item onClick={() => handleSetFilter(filters.genderFemale)}>
                <span className="bi bi-gender-female me-2" />
                Female
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12">
          <Table
            className="border"
            rowKey={(record) => record.id}
            dataSource={filteredData}
            columns={columns}
            loading={isFetching}
            bordered
          />
        </div>
      </div>
      <BackTop />

      {/* { isNewObject && (
        <div className="row">
          <div className="col-md-8 col-12">
            <NewPatientForm
              onSuccess={ fetchPatients }
              onExit={ () => setIsNewObject( false ) }
            />
          </div>
        </div>
      ) } */}
    </section>
  );
};

export { PatientsIndex };
