import { useMutation, useQuery } from 'react-query';
import { getAllMembershipPlans, getAllMembershipSponsors, postMembershipRegistration } from '../../../helpers/api';
import { Divider, message } from 'antd';
import _ from 'lodash';
import { useAtom } from 'jotai';
import { addMemberAtom, deleteMemberAtom, getMembersAtom, resetSponsoredMembershipAtom, updateMemberAtom } from '../../../helpers/state';
import { Chip, Modal, Select, Skeleton } from '@mantine/core';
import TeamMembersList from './team-list';
import { useState } from 'react';
import { NewMembershipSponsor } from './new-sponsor';
import smalltalk from 'smalltalk';

export const TeamRegistration = ( props ) => {

    // atoms
    const [ members ] = useAtom( getMembersAtom );
    const [ , deleteMember ] = useAtom( deleteMemberAtom );
    const [ , updateMember ] = useAtom( updateMemberAtom );
    const [ , addMember ] = useAtom( addMemberAtom );
    const [ , resetMembers ] = useAtom( resetSponsoredMembershipAtom );

    // states
    const [ state, setState ] = useState( { sponsorId: null, membershipType: 'sponsored' } );

    const [ modal, setModal ] = useState( {
        isOpen: false,
        title: '',
        content: null,
        size: "",
        zIndex: 60
    } );


    // handlers
    const { data: sponsors = [], isFetching: fetchingSponsors, refetch: refetchSponsors } = useQuery( {
        queryFn: getAllMembershipSponsors,
        queryKey: [ 'membership-sponsors' ],
    } );

    const { data: plans = [], isFetching, refetch: refetchPlans } = useQuery( {
        queryFn: getAllMembershipPlans,
        queryKey: [ 'all-membership-plans' ],
    } );


    const { mutateAsync: registerSponsoredMembers, isLoading } = useMutation( ( data ) => postMembershipRegistration( data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 201 ) {
                message.success( data.data.message );
                resetMembers();
                return;
            }

            throw data;
        },
        onError: ( error, variables, context ) => {
            const err = error?.response?.data?.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
    } );

    const registerMembers = () => {

        smalltalk.confirm(
            "Register Members", "Continue to register sponsored members?", {
            buttons: {
                ok: 'YES',
                cancel: 'NO',
            },
        }
        ).then( go => {


            if ( !state ) {
                message.error( 'sponsor required' );
                return;
            }

            let errored = false;
            for ( let index = 0; index < members.length; index++ ) {
                const mbr = members[ index ];

                if ( !mbr.firstName || !mbr.lastName || !mbr.gender || !mbr.dob || !mbr.contact || !mbr.planId ) {
                    message.error( 'all members fields are required' );
                    errored = true;
                    break;
                }


                if ( ( new Date() - new Date( mbr.dob ) ) < 0 ) {
                    message.error( `${ mbr.firstName }'s date of birth cannot be in the future` );
                    return;
                }
            }

            if ( errored )
                return;

            registerSponsoredMembers( { ...state, members } );

        } ).catch( ex => {
            message.error( 'error registering members' );
            return false;
        } );
    };


    return (
        <div className='row'>
            <Modal
                onClose={ () => setModal( { ...modal, isOpen: false } ) }
                opened={ modal.isOpen }
                title={ modal.title }
                size={ modal.size || 'md' }
                zIndex={ modal.zIndex }
            >
                { modal.content }
            </Modal>
            <div className="col-md-8 col-12 mt-5 mt-md-0">
                {/* customer selection section */ }
                <div className="field mb-0">
                    <label htmlFor="sponsorId">Sponsor</label>
                    {
                        fetchingSponsors ? <Skeleton visible={ true } /> :
                            <Select
                                id='sponsorId'
                                value={ state.sponsorId }
                                onChange={ v => setState( { ...state, sponsorId: v } ) }
                                nothingFound="No match"
                                size="md"
                                clearable
                                searchable
                                placeholder='select sponsor'
                                data={
                                    sponsors?.map( spon => {
                                        return {
                                            label: spon.sponsorName,
                                            value: spon.id
                                        };
                                    } )
                                }
                            />
                    }
                </div>
                <div className="d-flex mt-1">
                    <p
                        className='text-muted  hover-hand'
                        onClick={ () => setModal( {
                            isOpen: true,
                            title: 'Add Sponsor',
                            size: 500,
                            content: <NewMembershipSponsor onSuccess={ refetchSponsors } />,
                            zIndex: 500

                        } ) }
                    >
                        <span className="bi bi-plus-circle me-2"></span>
                        add sponsor
                    </p>
                </div>
            </div>
            <Chip className='mt-3' disabled>{ members?.length } Members</Chip>
            <div className="col-12 mt-3">
                {/* <span className='text-danger'>* all fields are required</span> */ }
                <div className="row g-1">
                    <div className="col-2 fw-bold">First Name</div>
                    <div className="col-2 fw-bold">Last Name</div>
                    <div className="col-1 fw-bold">Gender</div>
                    <div className="col-2 fw-bold">Date of Birth</div>
                    <div className="col-2 fw-bold">Primary Contact</div>
                    <div className="col-2 fw-bold">Plan</div>
                    <div className="col-1 fw-bold">#</div>
                </div>
                <Divider className='m-0' />
                {
                    members?.length > 0 &&
                    members.map( ( member, i ) =>
                        <div className='mb-2'>
                            <TeamMembersList
                                member={ member }
                                plans={ plans }
                                onUpdate={ updateMember }
                                onDelete={ deleteMember } />
                        </div>
                    )
                }

                <div className="mt-3">
                    <button onClick={ () => addMember() } className={ `button app-btn ${ isFetching && ' is-loading' }` }>
                        <span className='bi bi-plus-circle me-2' />
                        Add Member
                    </button>
                    {
                        ( members.length > 0 ) &&
                        <button
                            className='button app-btn is-ghost px-2 fw-bold ms-2'
                            onClick={ () => resetMembers() }
                        >
                            <span className="bi bi-trash me-2"></span>
                            clear
                        </button>
                    }
                </div>
            </div>


            <Divider />

            <div className="mt-4">
                <button
                    onClick={ registerMembers }
                    className={ `button h6 app-btn btn-prim ${ isLoading && ' is-loading ' }` }>
                    <span className="bi bi-check-all me-2"></span>
                    Register Members
                </button>
            </div>
        </div >
    );
};







