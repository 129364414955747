import { Alert } from "@mantine/core";
import { useAtom } from "jotai";
import {
  clearSponsorAtom,
  sponsorAtom,
} from "../../../helpers/state/membership";
import { appLinks, SPONSOR_TYPES } from "../../../helpers/config";
import { Divider } from "antd";
import { IconBrandGmail, IconBrandWhatsapp } from "@tabler/icons";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export function PublicRegistrationThankYouPage() {
  const [state, setState] = useAtom(sponsorAtom);
  const [, clearSponsor] = useAtom(clearSponsorAtom);

  const nav = useNavigate();

  useEffect(() => {
    !state.sponsorName && nav(appLinks.publicMembershipRegistration);
  }, []);

  return (
    <div>
      <>
        <div className="row p-5">
          <div className="col-md-4 col-11 mx-auto text-">
            <p className="display-4 fw-bold app-color mt-5">Nice 🎉</p>
            <Alert
              color="teal"
              title={<p className="display-6">Welcome aboard!</p>}
            >
              {state.sponsorType === SPONSOR_TYPES.organization ? (
                <>
                  <p>Your registration was successfully received!</p>
                  <p>
                    Your dedicated RiviaCare account manager will contact you
                    within 24 hours to schedule your onboarding session and
                    needs
                  </p>
                </>
              ) : (
                <div>
                  <p>
                    Your membership is activated once payment is confirmed,
                    instantly unlocking your benefits.
                  </p>
                  <p>
                    Need to finish payment? No stress, do it in{" "}
                    <a href={process.env.REACT_APP_HEALTH_PROFILE_URL}>
                      <strong>your health dashboard</strong>
                    </a>
                  </p>
                  <p>Glad to have you on board! 💙</p>
                </div>
              )}
            </Alert>
          </div>
        </div>
        <div className="d-flex">
          <div className="mx-auto">
            <button
              onClick={() => {
                clearSponsor();
                nav(appLinks.publicMembershipRegistration);
              }}
              className="button is-info is-light me-2"
            >
              {state.sponsorType === SPONSOR_TYPES.individual
                ? "Go Back"
                : "Done"}
            </button>
            {state.sponsorType === SPONSOR_TYPES.individual && (
              <button
                onClick={() =>
                  (window.location = process.env.REACT_APP_HEALTH_PROFILE_URL)
                }
                className="button is-info "
              >
                Go to Dashboard
              </button>
            )}
          </div>
        </div>
      </>

      <div className="my-4 mb-3 text-center row">
        <Divider />
        <span>For assistance or enquiries contact us</span>

        <div className="mx-auto my-2">
          <strong>
            <a
              href="https://api.whatsapp.com/send?phone=233247257966&text=Hello,%20%0AI%20have%20a%20question%20about"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconBrandWhatsapp />
              <span className="span ms-2">024 725 7966</span>
            </a>
          </strong>
        </div>
        <div className="mx-auto">
          <strong>
            <a href="mailto:care@riviaco.com">
              <IconBrandGmail />
              <span className="ms-2">care@riviaco.com</span>
            </a>
          </strong>
        </div>
      </div>
    </div>
  );
}
