import { appLinks, constants, primaryNav } from "../../helpers/config";
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import { BrowserView } from "react-device-detect";
import { Divider, Tag } from "antd";
import { Text, Menu, Group, Avatar, Modal, Badge } from "@mantine/core";
import {
  IconUser,
  IconLock,
  IconLogout,
  IconChevronRight,
  IconReplace,
  IconBuilding,
  IconUserPlus,
  IconMedicineSyrup,
  IconMicroscope,
  IconLungs,
  IconCirclePlus,
  IconReceipt2,
  IconNewSection,
} from "@tabler/icons";
import { getRole, getUser, logOut, resouces } from "../../helpers/auth";
import logo from "../../static/img/riviaos-logo.png";
import _ from "lodash";
import { putLogOut } from "../../helpers/api";
import { AttendanceComponent } from "../../pages/attendance";
import NewPatientForm from "../../pages/catalog/components/new-patient";
import GuestAttendanceRegistration from "./guest-attendance";
import NewBillForm from "../billing/new-bill";
import NewExpenseForm from "../../pages/facility/components/new-expense";
import NewServiceForm from "../../pages/facility/components/new-service";
import { NewDiagnosisForm, NewDrugForm, NewLabTestForm } from "./list-forms";
import {
  IconCopyCheck,
  IconMoneybag,
  IconUsersGroup,
} from "@tabler/icons-react";
import { MembershipStatus } from ".";
import { NewMembershipSponsor } from "../../pages/member/internal/new-sponsor";
import { MembershipRegistrationIndex } from "../../pages/member/internal";

const MobileNav = (props) => {
  const [activeNav, setActive] = useState("");

  return (
    <nav className="nav mobile-nav">
      <div className="nav-links px-3 py-2">
        {primaryNav.map((nav) => {
          return (
            nav.view !== "desktop" && (
              <NavLink
                className={(props) => {
                  props.isActive && setActive(nav.name);
                  return `${props.isActive ? "active-nav " : ""}`;
                }}
                end
                to={nav.url}
              >
                <p
                  className={`h5 p-0 m-0  bi ${
                    activeNav === nav.name ? nav.icon + "-fill" : nav.icon
                  }`}
                />
                <p className="nav-label p-0 m-0">{nav.name}</p>
              </NavLink>
            )
          );
        })}
      </div>
    </nav>
  );
};

const DesktopNav = (props) => {
  const [activeNav, setActive] = useState("");
  const user = getUser();

  const [modal, setModal] = useState({
    title: "",
    open: false,
    content: "",
    size: 700,
    zIndex: 50,
  });

  return (
    <nav className="nav desktop-nav">
      <Modal
        title={modal.title}
        opened={modal.open}
        size={modal.size}
        zIndex={modal.zIndex}
        onClose={() => setModal({ ...modal, open: false })}
      >
        {modal.content}
      </Modal>
      <div className="nav-links">
        <img src={logo} alt="rivia logo" width="150" />

        <div className="mb-3">
          <Badge>{user?.facility?.facilityName}</Badge>
        </div>
        <Divider />

        <Menu
          trigger="hover"
          withArrow
          transition="scale-y"
          shadow="md"
          position="bottom-left"
          width={200}
          className="d-block"
        >
          {/* <div className="buttons has-addons">
            <button className="app-btn btn-prim mt-3">
              <span className="bi bi-check-all" />
              <span className="fw-bold">Validate Member</span>
            </button>
          </div> */}

          <div className="mt-3 d-flex">
            <Menu.Target>
              <button className="app-btn btn-secondary">
                <span className="bi bi-plus-circle me-2" />
                Actions
              </button>
            </Menu.Target>
            {/* <button className="app-btn btn-prim fw-bold"
              onClick={ () => {
                setModal( {
                  content: <MembershipStatus />,
                  open: true,
                  size: 400,
                  title: 'Membership Status'
                } );
              } }
            >
              <span className="bi bi-check-all me-2" />
              Validate
            </button> */}
          </div>

          <Menu.Dropdown ml={50}>
            <Menu.Label>Add Resource</Menu.Label>
            <Menu.Item
              onClick={() =>
                setModal({
                  content: (
                    <NewPatientForm
                      onSuccess={() => null}
                      showBottomButtons
                      showCharm={false}
                    />
                  ),
                  title: "New Member",
                  open: true,
                  size: 600,
                })
              }
            >
              <IconUserPlus size={20} color="grey" className="me-2" />
              Member
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                setModal({
                  content: <NewBillForm onSuccess={() => null} />,
                  title: "New Bill",
                  open: true,
                  size: 1200,
                  zIndex: 50,
                })
              }
            >
              <IconReceipt2 size={20} color="grey" className="me-2" />
              Bill
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                setModal({
                  content: (
                    <NewExpenseForm
                      showFooter={true}
                      showHeader={false}
                      onSuccess={() => null}
                    />
                  ),
                  title: "New Expense",
                  open: true,
                  size: 500,
                  zIndex: 50,
                })
              }
            >
              <IconMoneybag size={20} color="grey" className="me-2" />
              Expenditure
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                setModal({
                  content: <NewServiceForm onSuccess={() => null} />,
                  title: "New Service",
                  open: true,
                  zIndex: 50,
                })
              }
            >
              <IconNewSection size={20} color="grey" className="me-2" />
              Service
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                setModal({
                  content: <NewDrugForm onSuccess={() => null} />,
                  title: "New Drug",
                  open: true,
                  zIndex: 50,
                })
              }
            >
              <IconMedicineSyrup size={20} color="grey" className="me-2" />
              Drug
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                setModal({
                  content: <NewDiagnosisForm onSuccess={() => null} />,
                  title: "New Diagnosis",
                  open: true,
                  zIndex: 50,
                })
              }
            >
              <IconLungs size={20} color="grey" className="me-2" />
              Diagnosis
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                setModal({
                  content: <NewLabTestForm onSuccess={() => null} />,
                  title: "New Lab Test",
                  open: true,
                  zIndex: 50,
                })
              }
            >
              <IconMicroscope size={20} color="grey" className="me-2" />
              Labs
            </Menu.Item>

            <Menu.Divider />
            <Menu.Label>Membership</Menu.Label>
            <Menu.Item
              onClick={() =>
                setModal({
                  content: <NewMembershipSponsor onSuccess={() => {}} />,
                  title: "New Sponsor",
                  open: true,
                })
              }
            >
              <IconCirclePlus size={20} color="grey" className="me-2" />
              Add Sponsor
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                setModal({
                  content: <MembershipRegistrationIndex />,
                  title: "Membership Registration",
                  open: true,
                  size: 1200,
                  zIndex: 50,
                })
              }
            >
              <IconUsersGroup size={20} color="grey" className="me-2" />
              Registration
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                setModal({
                  content: <MembershipStatus />,
                  open: true,
                  size: 500,
                  title: "Membership Status",
                });
              }}
            >
              <IconCopyCheck size={20} color="grey" className="me-2" />
              Check Status <Badge color="red">new</Badge>
            </Menu.Item>
            {/* <Menu.Item
              onClick={ () => setModal( {
                content: <GuestAttendanceRegistration />,
                title: "Register Guest Attendance",
                open: true
              } ) }
            >
              <IconWalk size={ 20 } color="grey" className="me-2" />
              Guest Attendance
            </Menu.Item> */}
          </Menu.Dropdown>
        </Menu>
        <Divider />

        {primaryNav.map((nav) => {
          return (
            nav.roles.includes(getRole()) &&
            nav.view !== "mobile" && (
              <NavLink
                className={(props) => {
                  props.isActive && setActive(nav.name);
                  return `${
                    props.isActive ? "active-nav " : ""
                  } flex align-items-center`;
                }}
                end
                to={nav.url}
              >
                <p
                  className={`h5 mb-0 me-3 p-0 m-0  bi ${
                    activeNav === nav.name ? nav.icon + "-fill" : nav.icon
                  }`}
                />
                <span className="d-none d-md-inline-block">{nav.name}</span>
                {nav.badge && (
                  <Badge color="red" size="xs" className="ms-2">
                    {nav.badge}
                  </Badge>
                )}
              </NavLink>
            )
          );
        })}
        <Divider />
        <Menu shadow="md" width={200} className="my-4 hover-hand">
          <Menu.Target>
            <Group>
              <Avatar radius="xl" />
              <div style={{ flex: 1 }}>
                <Text size="sm" weight={500} className="mb-0">
                  {user?.staff.name.slice(0, 12) + "..."}
                </Text>
                <Text color="dimmed" size="xs" className="mt-0">
                  {getRole()}
                </Text>
              </div>
              <IconChevronRight size={16} />
            </Group>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>Session</Menu.Label>
            <Menu.Item color="teal" icon={<IconBuilding size={14} />}>
              {user?.facility?.facilityName}
              <Tag className="d-block">
                {
                  user?.branches?.find((br) => br.id === user.branchId)
                    ?.branchName
                }
              </Tag>
            </Menu.Item>
            <Link to={appLinks.switchBranch} className="text-secondary">
              <Menu.Item icon={<IconReplace size={14} />}>
                Switch Branch
              </Menu.Item>
            </Link>
            <Menu.Divider />
            <Menu.Label>Security</Menu.Label>
            <Link to={appLinks.profile.index} className="text-secondary">
              <Menu.Item icon={<IconUser size={14} />}>Profile</Menu.Item>
            </Link>
            <Link to={appLinks.forgotPassword} className="text-secondary">
              <Menu.Item icon={<IconLock size={14} />}>
                Change Password
              </Menu.Item>
            </Link>
            <Menu.Divider />
            <Menu.Item color="red" icon={<IconLogout size={14} />}>
              <Link
                to="#"
                onClick={() => {
                  putLogOut();
                  logOut();
                  window.location.href = appLinks.login;
                }}
                className="text-danger"
              >
                Log Out
              </Link>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
        {/* <div
          className='d-none d-md-inline-block'
          style={ {
            position: 'fixed',
            bottom: '40px',
            left: '30px'
          } }>
          <AttendanceComponent />
        </div> */}
      </div>
    </nav>
  );
};

const TopNav = (props) => {
  return (
    <nav className="nav top-nav px-md-4 px-3 py-2">
      {/* <div className="p-2"> */}
      <div>
        <h6>{constants.siteTitle}</h6>
      </div>
      <BrowserView>
        <div>
          <input type="search" placeholder="search" className="input" />
        </div>
      </BrowserView>
      <div>
        {/* date */}
        {/* <span className="h5 bi bi-search mx-2"></span> */}
        <span className="h5 bi bi-bell"></span>
      </div>
      {/* </div> */}
    </nav>
  );
};

export default TopNav;
export { MobileNav, DesktopNav, TopNav };
