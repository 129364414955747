import { Alert, Badge, Box, Card, Text } from "@mantine/core";
import { NewMembershipRegistration } from "./new-registration";
import { useAtom } from "jotai";
import {
  clearSponsorAtom,
  sponsorAtom,
} from "../../../helpers/state/membership";
import { appLinks, SPONSOR_TYPES } from "../../../helpers/config";
import { useState } from "react";
import { Divider } from "antd";
import { IconBrandGmail, IconBrandWhatsapp } from "@tabler/icons";
import { cedisLocale } from "../../../helpers/utilities";
import { useNavigate } from "react-router-dom";

export function PublicMembershipRegistrationIndex() {
  // atom
  const [state, setState] = useAtom(sponsorAtom);
  const [, clearSponsor] = useAtom(clearSponsorAtom);

  // state
  const [page, setPage] = useState(1);
  const nav = useNavigate();

  const membershipCost = 30;

  const handleTypeChange = (type) => {
    let teamSize,
      payNow = false;

    if (type === SPONSOR_TYPES.individual) {
      teamSize = 1;
      payNow = true;
    } else {
      teamSize = state.teamSize || 0;
    }

    setState({ sponsorType: type, teamSize, payNow });
    setPage(2);
  };

  return (
    <div>
      <div className="row text-center mt-3">
        <div className="col-10 mx-auto mt-4">
          <p className="h4 mb-0">
            Welcome to <strong className="app-color">RiviaCare</strong>
          </p>
          <p className="display-4 m-0 p-0">Healthcare re-imagined!</p>
          {page !== 3 && (
            <Badge size="lg" className="mt-3">
              PROVIDE YOUR DETAILS TO GET STARTED
            </Badge>
          )}
        </div>
      </div>
      {page === 1 && (
        <div className="mt-5">
          <div className="mt-5 d-flex flex-column align-items-center gap-3">
            <p className="text-muted h6 mb-3">Who is registering?</p>
            <button
              onClick={() => handleTypeChange(SPONSOR_TYPES.individual)}
              className={`button p-5 d-flex flex-column justify-content-center align-items-center 
                                ${
                                  state.sponsorType ===
                                    SPONSOR_TYPES.individual && "is-info"
                                }`}
              style={{ width: "16rem" }}
            >
              <i className="bi bi-person-arms-up h4" />
              <span>Individual</span>
            </button>
            <button
              onClick={() => handleTypeChange(SPONSOR_TYPES.organization)}
              className={`button p-5 d-flex flex-column justify-content-center align-items-center 
                                ${
                                  state.sponsorType ===
                                    SPONSOR_TYPES.organization && "is-info"
                                }`}
              style={{ width: "16rem" }}
            >
              <i className="bi bi-shop h4" />
              <span>Organization</span>
            </button>
          </div>
        </div>
      )}

      {page === 2 && (
        <div className="row mt-3 ml-5 ml-md-0">
          <div className="col-md-4 col-10 offset-md-3 ">
            <NewMembershipRegistration
              onBack={() => setPage(1)}
              onSuccess={() => nav(appLinks.publicRegistrationComplete)}
            />
          </div>
          <div className="col-md-3 col-10 mt-3 mb-5 ">
            <Card withBorder shadow="sm" radius="md">
              <Card.Section withBorder inheritPadding py="xs" align="center">
                <Text weight={500}>Total Cost</Text>
              </Card.Section>

              <Text color="dimmed" size="lg" align="center" className="mb-0">
                <Badge color="blue">GHS</Badge>
                <div className="m-0">
                  <span className="display-3 m-0">
                    {cedisLocale.format(
                      (state.teamSize || 0) * membershipCost * 12
                    )}
                  </span>
                  <small className="text-muted">/yr</small>
                  <Box>
                    <small>
                      30
                      {state.sponsorType === SPONSOR_TYPES.organization &&
                        "/person"}
                      /mth
                    </small>
                  </Box>
                </div>
              </Text>
            </Card>
            {state.teamSize > 0 && (
              <div>
                <Alert
                  color="green"
                  className="mt-4"
                  title="This is 98% cheaper than private insurance & 4x better health reimbursements. Signup Now!"
                />
              </div>
            )}
          </div>
        </div>
      )}
      {/* {page === 3 && (
        <>
          <div className="row p-5">
            <div className="col-md-4 col-11 mx-auto text-">
              <p className="display-4 fw-bold app-color mt-5">🎉 Nice!</p>
              <Alert
                color="teal"
                title={<p className="display-6">Welcome aboard</p>}
              >
                {state.sponsorType === SPONSOR_TYPES.organization ? (
                  <>
                    <p>Your registration was successfully received!</p>
                    <p>
                      Your dedicated RiviaCare account manager will contact you
                      within 24 hours to schedule your onboarding session and
                      needs
                    </p>
                  </>
                ) : (
                  <div>
                    <p>Thank you for choosing RiviaCare.</p>
                    <p>
                      {" "}
                      Get started by visiting our clinics or virtual care points
                      for the care you deserve!
                    </p>
                  </div>
                )}
              </Alert>
            </div>
          </div>
          <div className="row">
            <button
              onClick={() => {
                setPage(1);
                clearSponsor();
              }}
              className="button is-info is-light col-1 mx-auto"
            >
              OK
            </button>
          </div>
        </>
      )} */}

      <div className="my-4 mb-3 text-center row">
        <Divider />
        <span>For assistance or enquiries contact us</span>

        <div className="mx-auto my-2">
          <strong>
            <a
              href="https://api.whatsapp.com/send?phone=233247257966&text=Hello,%20%0AI%20have%20a%20question%20about"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconBrandWhatsapp />
              <span className="span ms-2">024 725 7966</span>
            </a>
          </strong>
        </div>
        <div className="mx-auto">
          <strong>
            <a href="mailto:care@riviaco.com">
              <IconBrandGmail />
              <span className="ms-2">care@riviaco.com</span>
            </a>
          </strong>
        </div>
      </div>
    </div>
  );
}

//  <script src="./script/meta-pixel.js" />
//       <script src="./script/linkedin.js" />
