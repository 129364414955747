import { atom } from 'jotai';


// templates
const sponsorTemplate = {
    sponsorName: '',
    sponsorType: '',
    gender: '',
    contact: '',
    email: '',
    location: '',
    teamSize: 0,
    payNow: false,
    dob: null
};


// state
const _sponsor = atom( sponsorTemplate );


// atoms
export const sponsorAtom = atom(
    ( get ) => get( _sponsor ),
    ( get, set, sponsor ) => {
        set( _sponsor, { ...get( _sponsor ), ...sponsor } );
    }
);

export const clearSponsorAtom = atom(
    null,
    ( get, set, sponsor ) => {
        set( _sponsor, sponsorTemplate );
    }
);

// MEMBERSHIP REGISTRATION
// export const membershipRegistrationAtom = atom(
//     get => get( _membershipRegistration ),
//     ( get, set, registration ) => {
//         set( _membershipRegistration, { ...get( _membershipRegistration ), ...registration } );
//     }
// );

// export const clearMembershipRegistrationAtom = atom(
//     null,
//     ( get, set, registration ) => {
//         set( _membershipRegistration, membershipRegistrationTemplate );
//     }
// );

