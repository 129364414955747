import { useQuery } from "react-query";
import {
  getAllMembershipPlans,
  getMembershipSubscription,
} from "../../../helpers/api";
import { Alert, Badge, Loader, Modal, Paper, Switch } from "@mantine/core";
import _ from "lodash";
import { MemberBenefits } from "../../member/benefits";
import { Divider, Select } from "antd";
import { useState } from "react";
import { RequiredIndicator } from "../../../components";
import {
  calSubscriptionEndDate,
  cedisLocale,
} from "../../../helpers/utilities";
import { MEMBERSHIP_CYCLES } from "../../../helpers/config";

export const EditMemberPlan = ({ membershipId }) => {
  const {
    data: sub,
    isError,
    isFetching,
  } = useQuery({
    queryFn: () => getMembershipSubscription(membershipId),
    queryKey: ["membership", membershipId],
  });

  const [modal, setModal] = useState({
    title: "",
    open: false,
    content: "",
    size: 500,
    zIndex: 55,
  });

  return (
    <>
      <Modal
        title={modal.title}
        opened={modal.open}
        size={modal.size}
        onClose={() => setModal({ ...modal, open: false })}
        zIndex={modal.zIndex}
      >
        {modal.content}
      </Modal>
      <Alert color={sub?.isActive ? "teal" : "red"} variant="filled">
        <h5 className="text-white m-0 d-flex justify-content-between align-items-center">
          {`${sub?.plan.planName}`}
          <Badge color={sub?.isActive ? "teal" : "red"} className="ms-2">
            {sub?.isActive ? "Active" : "InActive"}
          </Badge>
        </h5>
      </Alert>
      <Alert color={sub?.isActive ? "teal" : "red"} variant="light">
        <p>
          {sub?.plan.description}
          <br />
        </p>
        <div className="row">
          <div className="col-3 fw-bold">Type:</div>
          <div className="col-9">
            {_.capitalize(sub?.membershipType)} Registration
          </div>

          <div className="col-3 fw-bold">Term:</div>
          <div className="col-9">{_.capitalize(sub?.cycle)}</div>

          <div className="col-3 fw-bold">Started on:</div>
          <div className="col-9">{new Date(sub?.startDate).toDateString()}</div>

          <div className="col-3 fw-bold">Expiring on:</div>
          <div className="col-9">
            {new Date(
              calSubscriptionEndDate(sub?.cycle, sub?.startDate)
            ).toDateString()}
          </div>
        </div>
        {sub?.sponsor && (
          <>
            <Divider />
            <>
              <Badge color="cyan" variant="filled">
                Sponsor
              </Badge>
              <p className="h6 pt-2 pb-0 mb-0">{sub?.sponsor?.sponsorName}</p>
              <small>{sub?.sponsor?.sponsorType}</small>
            </>
          </>
        )}
        <div>
          <button
            className="button app-btn mt-3"
            onClick={() =>
              setModal({
                title: "Member Benefits",
                open: true,
                content: <MemberBenefits planId={sub?.plan.id} />,
                size: 600,
              })
            }
          >
            see benefits
          </button>
        </div>
      </Alert>
    </>
  );
};

export const AddMemberPlan = ({ onUpdate, data }) => {
  const { Option } = Select;

  const { data: plans, isFetching } = useQuery({
    queryFn: () => getAllMembershipPlans(),
    queryKey: ["plans"],
  });

  return (
    <>
      {isFetching && (
        <Loader shadow="xs" padding="lg" className="text-center">
          Loading...
        </Loader>
      )}
      <h3>Subscribe Member to a Plan</h3>
      <Divider />
      <div className="row">
        <div className="col-md-7">
          <div className="field col-12">
            <label className="mb-0" htmlFor="plan">
              Select Plan
              <RequiredIndicator />
            </label>
            <Select
              required
              allowClear
              showSearch
              value={data?.planId}
              onChange={(v) => onUpdate("planId", v)}
              size="large"
              id="plan"
              className="d-block"
              placeholder="membership plan"
            >
              {plans?.map((plan) => (
                <Option value={plan.id}>{plan.planName}</Option>
              ))}
            </Select>
          </div>
          <div className="field col-12">
            <label className="mb-0" htmlFor="cycle">
              Select Cycle
              <RequiredIndicator />
            </label>
            <Select
              required
              allowClear
              showSearch
              value={data?.cycle}
              onChange={(v) => onUpdate("cycle", v)}
              size="large"
              id="cycle"
              className="d-block"
              placeholder="subscription term"
            >
              <Option value="annual">Annual</Option>
              <Option value="quarterly">Quarterly</Option>
              <Option value="monthly">Monthly</Option>
            </Select>
          </div>
          <div className="field col-12 mt-4">
            <Switch
              size="lg"
              label="I confirm receipt of payment"
              onLabel="Paid"
              offLabel="Not Paid"
              checked={data?.isPaid}
              onChange={(v) => onUpdate("isPaid", !data?.isPaid)}
            />
          </div>
        </div>
        <div className="col-md-5">
          <Alert color="teal" title="Membership Cost" variant="light">
            {!data?.cycle && !data.planId ? (
              <p>Select a plan and cycle to view membership cost</p>
            ) : (
              <>
                <p>
                  Based on the selected plan and cycle, total membership cost
                  will be
                </p>
                <div className="d-flex">
                  GHS{" "}
                  <h1 className="ms-2">
                    {cedisLocale.format(30 * MEMBERSHIP_CYCLES(data.cycle))}
                  </h1>
                </div>
              </>
            )}
          </Alert>
        </div>
      </div>
    </>
  );
};
