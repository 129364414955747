import { Divider, message, Input } from "antd";
import _ from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader, PatientFinderResultCard } from "../components";
import { appLinks } from "../helpers/config";
import lostImg from "../static/svg/lost.svg";
import NewPatientForm from "./catalog/components/new-patient";
import {
  getAllNetworkPatients,
  getAllPatients,
  postNewConsultation,
} from "../helpers/api";
import { useMutation, useQuery } from "react-query";
import { Loader, Modal } from "@mantine/core";
import AddVitals from "./schedule/components/add-vitals";
import { calcAge, hasPermission, updateData } from "../helpers/utilities";
import { action, resouces } from "../helpers/auth";
import { consultationSchema, vitalsSchema } from "../helpers/schemas";
import smallTalk from "smalltalk";

const StartConsultation = (props) => {
  const [addPatient, setAddPatient] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [selected, setSelected] = useState({});
  const [vitals, setVitals] = useState({});
  const [consultation, setConsultation] = useState({});

  const navigate = useNavigate();

  const {
    data: patients,
    refetch: fetchAllPatients,
    isFetching,
  } = useQuery({
    queryFn: getAllNetworkPatients,
    queryKey: ["patients"],
    onSuccess: (data) => setFilteredData(data),
  });

  const { mutateAsync: createConsultation, isLoading } = useMutation(
    (data) => postNewConsultation(data),
    {
      onSuccess: (data, variables, context) => {
        if (data.status === 201) {
          message.success(data.data.message);
          reset();
          return;
        }
        throw data;
      },
      onError: (error, variables, context) => {
        const err = error?.response?.data?.message;
        // if ( !err ) {
        //   message.error( 'error processing request!' );
        //   return;
        // }

        if (_.isArray(err)) {
          err.map((err) => message.error(err.message));
        } else {
          message.error(err);
        }
      },
      retry: true,
    }
  );

  // methods
  const reset = () => {
    setConsultation({});
    setVitals({});
    setSelected({});
  };

  const handleSearch = _.debounce(
    (e) => {
      const { value } = e.target;
      if (value === "") {
        setFilteredData(patients);
        return;
      }

      setFilteredData(
        patients.filter(
          (p) =>
            p.firstName.toLowerCase().includes(value.toLowerCase()) ||
            p.lastName.toLowerCase().includes(value.toLowerCase()) ||
            // p.insuranceNumber.toLowerCase().includes( value.toLowerCase() ) ||
            p.contact.toLowerCase().includes(value.toLowerCase())
        )
      );
    },

    300,
    {
      leading: false,
      trailing: true,
    }
  );

  const saveConsultation = () => {
    const schemaOptions = {
      abortEarly: true,
      stripUnknown: true,
    };

    smallTalk
      .confirm(
        "Save Vitals",
        "This will start a new consultation. Do you wish to continue?",
        {
          buttons: {
            ok: "YES",
            cancel: "NO",
          },
        }
      )
      .then((go) => {
        consultationSchema
          .validate(consultation, schemaOptions)
          .then((valid) => {
            vitalsSchema
              .validate(vitals, schemaOptions)
              .then((valid) => {
                const cons = { ...consultation, isInstant: false };
                createConsultation({ vitals, consultation: cons });
              })
              .catch((err) => {
                if (_.isArray(err.errors)) {
                  message.error(err.errors[0]);
                } else {
                  message.error(err);
                }
              });
          });
      })
      .catch((ex) => {
        return false;
      });
  };

  const continueConsultation = () => {
    navigate(appLinks.care.new, {
      state: {
        patient: selected,
        vitals,
      },
    });
  };

  return (
    // <div className="quick-page">
    <div className="row patient-finder">
      <div className="mx-auto col-md-10 col-12 content">
        <PageHeader
          title={!selected ? "Start Consultation" : "Patient Vitals"}
          description="retrieve patient's vital to start the consultation "
          hasBackButton
        >
          {!_.isEmpty(selected) &&
            (hasPermission(resouces.consultations, action.canDelete) ? (
              <>
                <button
                  className={`button ${isLoading && " is-loading"} `}
                  onClick={saveConsultation}
                >
                  <span className="bi bi-save me-2"></span>
                  Save
                </button>
                <button
                  disabled={isLoading}
                  className={`mx-2 button is-info  `}
                  onClick={continueConsultation}
                >
                  <span className="bi bi-arrow-right me-2"></span>
                  Continue
                </button>
              </>
            ) : (
              <button
                className={`button is-info ${isLoading && " is-loading"} `}
                onClick={saveConsultation}
              >
                <span className="bi bi-save me-2"></span>
                Save
              </button>
            ))}

          <button
            className="button bg-danger"
            disabled={isLoading}
            onClick={() => {
              !_.isEmpty(selected) ? setSelected({}) : navigate(-1);
            }}
          >
            <span className="bi bi-x h5 text-white m-0"></span>
            {/* Home */}
          </button>
        </PageHeader>
        <Divider />
        {_.isEmpty(selected) && (
          <div className="row">
            <div className="field col-md-5 col-12">
              <h5>Start typing below to search</h5>
              <p>Search by</p>
              <ul className="ms-0">
                <li key="1">Insurance number</li>
                <li key="2">Patient phone number</li>
                <li key="3">Patient Name</li>
              </ul>
              <div>
                <input
                  className="input"
                  type="search"
                  onChange={handleSearch}
                  autoFocus
                  id="searchQuery"
                  placeholder="search here"
                />
              </div>
              <Divider />
              {/* <p className="mb-1">can't find patient,</p> */}
              <button
                className="app-btn btn-prim mt-0 p-3 h6"
                onClick={() => setAddPatient(true)}
              >
                <span className="bi bi-plus-circle me-2"></span>
                New Patient
              </button>
            </div>
            <div
              style={{
                maxHeight: "70vh",
                // overflow: "scroll"
              }}
              className="mt-md-0 mt-3 col-md-7 col-12 results"
            >
              {filteredData.length === 0 && isFetching && (
                <div className="text-center">
                  <Loader />
                  <p className="mt-4">Fetching patients... </p>
                </div>
              )}
              {filteredData.length === 0 && !isFetching && (
                <div className="text-center">
                  <img src={lostImg} width="200" height="200" />
                  <h5 className="mt-3">No Patients found!</h5>
                </div>
              )}
              {filteredData.length > 0 &&
                filteredData.map((patient) => (
                  <PatientFinderResultCard
                    key={patient.id}
                    patient={patient}
                    onSelect={(data) => {
                      setSelected(data);

                      //set consultation required fields
                      setConsultation({
                        patientId: data.id,
                        type: "Physical",
                        doctorsAdvise: "nill",
                        complaint: "nill",
                        folderNumber: "",
                      });
                    }}
                    className="my-1"
                  />
                ))}
            </div>
          </div>
        )}
        {!_.isEmpty(selected) && (
          <>
            <div className="row">
              <div className="col-md-3 col-6">
                <span className="bi bi-person text-muted"> Patient Name</span>
                <h4>{`${selected?.firstName} ${selected?.lastName}`}</h4>
              </div>
              <div className="col-md-3 col-6">
                <span className="bi bi-calendar text-muted"> Age</span>
                <h4>{calcAge(selected?.dob).age}</h4>
              </div>
              <div className="col-md-3 col-6">
                <span className="bi bi-person text-muted"> Gender</span>
                <h4>{selected.gender}</h4>
              </div>
              <div className="col-md-3 col-6">
                <span className="bi bi-telephone text-muted"> Contact</span>
                <h4>{selected.contact}</h4>
              </div>
            </div>
            <Divider />
            {/* <div className="row"> */}
            {/* <div className="col-md-6 col-12"> */}
            <AddVitals
              data={vitals}
              onUpdate={(field, data) =>
                updateData(vitals, setVitals, field, data)
              }
            />
            {/* </div> */}
            {/* <div className="col-md-6 col-12 mt-3 mt-md-0"> */}
            {/* <div className="row">
                    <div className="col-3">Folder Number:</div>
                    <div className="col-9">
                      <Input
                        size='large'
                        value={ consultation.folderNumber }
                        type="text"
                        onChange={ ( e ) => setConsultation( { ...consultation, folderNumber: e.target.value } ) }
                        id="folderNumber"
                        placeholder="patient folder number"
                      />
                    </div>
                  </div>
                  <div className="row my-4">
                    <div className="col-3">Notes:</div>
                    <div className="col-9">
                      <textarea
                        value={ consultation.comments }
                        rows="4"
                        className="textarea"
                        onChange={ ( e ) => setConsultation( { ...consultation, comments: e.target.value } ) }
                        id="comments"
                        placeholder="input your notes or comments here"
                      />
                    </div>
                  </div> */}

            {/* </div> */}
            {/* </div> */}
          </>
        )}
      </div>

      <Modal
        title="Add Patient"
        opened={addPatient}
        onClose={() => setAddPatient(false)}
        size={600}
      >
        <NewPatientForm
          onSuccess={fetchAllPatients}
          showCharm={false}
          showBottomButtons={true}
        />
      </Modal>
    </div>
  );
};

export { StartConsultation };
